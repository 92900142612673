import React from 'react'
import ContentWrapper from '../components/ContentWrapper/ContentWrapper'
import NewFooter from '../components/landing/NewFooter'

export default function PrivacyPolicy() {
  return (
    <>

  <ContentWrapper
      type={"PrivacyPolicy"}
      title={["Privacy","POLICY"]}
      content={"<h1>Just and HTML</h1>"}
      />

    <NewFooter />
      
    </>
  )
}
