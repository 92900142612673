import React from "react";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import Footer from "../components/landing/Footer";
import { useSelector } from "react-redux";
import NewFooter from "../components/landing/NewFooter";

export default function AboutUs() {
  return (
    <>
      <ContentWrapper
        type={"AboutSection"}
        title={["About", "US"]}
        content={"<h1>Just and HTML</h1>"}
      />

      <NewFooter />
    </>
  );
}
