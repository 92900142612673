import React, { useEffect, useState } from "react";
import { fetchBartenderDetailsApi, fetchUserData } from "../axios/axios";
import BartenderWrapperDash from "../components/dashboard/WrapperDash";
import { useLocation, useNavigate } from "react-router-dom";
import BarEdit from "../components/EditComponent/BarEdit";
import BarEdit2 from "../components/EditComponent/BarEdit2";
import BarEdit3 from "../components/EditComponent/BarEdit3";
import BarEdit4 from "../components/EditComponent/BarEdit4";
import BarEdit5 from "../components/EditComponent/BarEdit5";
import BarEdit6 from "../components/EditComponent/BarEdit6";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

export default function EditBarProfileWrapper() {
  const location = useLocation();
  const { passactive } = location.state;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (passactive) {
      setActiveTab(passactive);
    }
  }, []);

  return (
    <div>
      <img
        loading="lazy"
        src="/images/completeProfile/complete_Profile_backImg.png"
        className="fixed inset-0 object-cover size-full"
      />

      <div
        style={{
          backgroundImage: `url('/images/headerBackground.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "0 0 25px 25px",
        }}
        className="flex overflow-hidden relative px-20 text-2xl w-[100%] text-white min-h-[100px] rounded-b-3xl max-md:flex-wrap max-md:px-5">
        <div className="flex min-w-full my-auto">
          <div
            onClick={() => navigate(`/profile`)}
            className="border-2 px-4 rounded-[10px] cursor-pointer py-1 mr-5">
            &#10094;
          </div>
          <span>Edit Your Profile</span>
        </div>
      </div>
      <div className="flex relative justify-center items-center self-center px-16 py-10 mx-auto my-7 rounded-[20px] w-full bg-white text-xs max-w-[1099px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
        {activeTab == 1 && <BarEdit />}
        {activeTab == 2 && <BarEdit2 />}
        {activeTab == 3 && <BarEdit3 />}
        {activeTab == 4 && <BarEdit4 />}
        {activeTab == 5 && <BarEdit5 />}
        {activeTab == 6 && <BarEdit6 />}
      </div>
    </div>
  );
}
