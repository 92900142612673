import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { createEarlyRegi, createEarlyRegiBar } from '../../axios/axios';

function EstabLanding06() {
    const [data, setdata] = useState({
        fullName: "",
        email: "",
        phoneNo: "",
        location: "",
        comments: "",
    });

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setdata({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateFormData(data)) {
            createEarlyRegiBar({ data })
                .then((res) => {
                    toast.success(res.data.message);
                    setdata({
                        fullName: "",
                        email: "",
                        phoneNo: "",
                        location: "",
                        comments: "",
                    });
                })
                .catch((err) => toast.error(err?.message));
        }
    };

    const validateFormData = (data) => {
        let isValid = true;

        if (!data.fullName) {
            toast.error("Full Name is required");
            isValid = false;
            return;
        }

        if (!data.email) {
            toast.error("Email is required");
            isValid = false;
            return;
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            toast.error("Email is invalid");
            isValid = false;
            return;
        }

        if (!data.phoneNo) {
            toast.error("Phone Number is required");
            isValid = false;
            return;
        } else if (!/^\d+$/.test(data.phoneNo)) {
            toast.error("Phone Number is invalid");
            isValid = false;
            return;
        } else if (data.phoneNo.length < 10) {
            toast.error("Phone Number must be at least 10 digits long");
            isValid = false;
            return;
        }

        if (!data.location) {
            toast.error("Location is required");
            isValid = false;
            return;
        }

        return isValid;
    };
    return (
        <div className='relative flex items-center justify-center w-screen h-screen gap-3 px-32 bg-center bg-no-repeat bg-cover bg-Body-2'>
            <img src="/images/Cloud_Section_06.svg" alt="" className='absolute top-0 object-contain w-screen h-auto -translate-y-[10%]' />
            <div className='w-1/2'>
                <h1 className='text-4xl font-normal leading-loose text-white font-Righteous'>Join<span className='text-4xl font-Righteous text-error'> Barooom </span>Today!</h1>
                <p className='font-normal text-white font-poppins '>If you're ready to take the next step in your bartending or bar staff career in Charlotte, Barooom is the platform for you. We make it easy to find the right job, no matter your skill level or experience. Download the Barooom app today and start exploring opportunities that fit your lifestyle. </p>
                <p className='mt-4 font-normal text-white font-poppins'>With Barooom, your next great job is just a tap away!</p>
            </div>
            <div className='w-[45%]'>
                <div class="custom-glass-form">
                    <form className='px-5 py-8 bg-white rounded-xl'>
                        <h1 className='text-2xl font-normal text-center font-Righteous'>Sign Up Now for Early Access!</h1>
                        <div className='flex items-center justify-center w-full'>
                            <div className='w-32 h-[0.2rem] my-4 rounded-full bg-estab-back' />

                        </div>
                        <div className='flex flex-col w-full h-full gap-5'>
                            <label>
                                <p className='text-black font-Righteous'>Full name <span className='text-error font-Righteous'>*</span></p>
                                <input style={{ border: '1px solid #CBD6E2' }} type="text-black text"
                                    value={data.fullName}
                                    name='fullName'
                                    onChange={(e) => handleChange(e)}
                                    className='w-full h-10 text-base rounded-full ' />
                            </label>
                            <label>
                                <p className='text-black font-Righteous'>Email Address <span className='text-error font-Righteous'>*</span></p>
                                <input style={{ border: '1px solid #CBD6E2' }} type="text-black text"
                                    value={data.email}
                                    name='email'
                                    onChange={(e) => handleChange(e)}
                                    className='w-full h-10 text-base rounded-full ' />
                            </label>
                            <label>
                                <p className='text-black font-Righteous'>Phone Number <span className='text-error font-Righteous'>*</span></p>
                                <input style={{ border: '1px solid #CBD6E2' }} type="text-black text"
                                    value={data.phoneNo}
                                    name='phoneNo'
                                    onChange={(e) => handleChange(e)}
                                    className='w-full h-10 text-base rounded-full ' />
                            </label>
                            <label>
                                <p className='text-black font-Righteous'>Location <span className='text-error font-Righteous'>*</span></p>
                                <input style={{ border: '1px solid #CBD6E2' }} type="text-black text"
                                    value={data.location}
                                    name='location'
                                    onChange={(e) => handleChange(e)}
                                    className='w-full h-10 text-base rounded-full ' />
                            </label>
                            <label>
                                <p className='text-black font-Righteous'>Other Comments</p>
                                <input style={{ border: '1px solid #CBD6E2' }} type="text-black text"
                                    value={data.comments}
                                    name='comments'
                                    onChange={(e) => handleChange(e)}
                                    className='w-full h-10 text-base rounded-full ' />
                            </label>

                        </div>
                        <button className='flex items-center justify-center w-full h-10 gap-2 px-2 mt-4 font-semibold text-white rounded-full bg-estab-back font-poppins' onClick={(e) => handleSubmit(e)}>Get Started <img src="/images/arrow.svg" alt="" /></button>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default EstabLanding06