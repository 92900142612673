import React from 'react'
import Wrapper from '../authWrapper/Wrapper'
import BartenderDashboard from '../dashboard/Bartender/mains/mains'
import BartenderWrapperDash from '../dashboard/WrapperDash'
import DashHeader from '../dashboard/DashHeader'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PageHeaderNav from '../../pages/PageHeaderNav'
import NewFooter from '../landing/NewFooter'

function StripeSuccess() {
    const user = useSelector((state) => state.user.user)
    const navigate = useNavigate()
    return (
        <>
            {!user ?
                <section >
                    <PageHeaderNav hamColor={'text-black'} textColor={'text-black'} />
                    <div className='flex flex-col items-center justify-center w-screen h-[50vh] py-32'>
                        <h1 className='w-full py-4 text-xl text-center text-black font-poppins'>
                            Payments Account <span className='text-establishment-red'>Successfully Completed.</span>
                        </h1>

                        <button onClick={() => navigate('/dashboard')} className='p-2 rounded-lg cursor-pointer bg-yellow-1'>Go To Home</button>
                    </div>
                    <NewFooter />
                </section>
                :
                <BartenderWrapperDash>
                    <DashHeader
                        leftContent={
                            <>
                                <div className="pt-3 my-auto uppercase grow">
                                    <span className=" text-neutral-700">Welcome</span>{" "}
                                    <span className="text-yellow-400 ">{user?.name}</span>
                                </div>
                            </>
                        }
                    />
                    <div className='flex flex-col items-center justify-center w-full h-[80%]'>
                        {user.connected_acc_setup == "1" &&
                            <h1 className='w-full py-4 text-4xl text-center text-black font-poppins'>
                                Payments Account <span className='text-establishment-red'>Under Review.</span>
                            </h1>
                        }
                        {user.connected_acc_setup == "2" &&
                            <h1 className='w-full py-4 text-4xl text-center text-black font-poppins'>
                                Payments Account <span className='text-establishment-red'>Successfully Approved.</span>
                            </h1>
                        }
                        <button onClick={() => navigate('/dashboard')} className='p-4 cursor-pointer bg-yellow-1 rounded-xl'>Go To dashboard</button>
                    </div>
                </BartenderWrapperDash>
            }
        </>

    )


}

export default StripeSuccess