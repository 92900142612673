import React from 'react'

function EstabLanding05() {
    const data = [
        {
            image: '/images/Section_05_01.png',
            title: 'Access to a Wide Range of Jobs',
            description: `With Barooom, you can explore various job openings across Charlotte, ranging from upscale cocktail lounges to casual sports bars. Our platform connects you to establishments looking for bartenders, mixologists, servers, and more. Whether you specialize in craft cocktails, beer, wine, or providing excellent customer service, there's a job waiting for you on Barooom.`
        },
        {
            image: '/images/Section_05_02.png',
            title: 'Flexibility and Control',
            description: 'Looking for a weekend gig, or do you prefer a full-time bartending job? Barooom offers flexible opportunities that allow you to choose shifts that fit your lifestyle. With the app’s intuitive scheduling features, you can select your preferred working hours, locations, and types of establishments. This flexibility is perfect for those who have other commitments or who prefer a diverse work environment.'
        },
        {
            image: '/images/Section_05_03.png',
            title: 'Tailored Job Matches',
            description: 'Barooom uses advanced algorithms to match your skills and experience with job opportunities that fit your profile. Whether you’re a master at mixing cocktails or excel in providing top-notch customer service as a server, Barooom ensures that you’re connected to gigs that are aligned with your talents and career goals.'
        },
        {
            image: '/images/Section_05_04.png',
            title: 'Build Your Reputation',
            description: 'Barooom allows you to build a professional profile where you can showcase your skills, certifications, and experience. Establishments can leave reviews and ratings based on your performance, helping you build a solid reputation in the industry. The better your reviews, the more likely you are to land high-paying gigs and premium shifts.'
        },
        {
            image: '/images/Section_05_05.png',
            title: 'Fast and Easy Application Process',
            description: 'Say goodbye to long and tedious job applications! Barooom streamlines the process, allowing you to apply for jobs with just a few taps. Once you create your profile, you’ll have access to a wide range of opportunities and can apply for multiple jobs at once. Our goal is to get you working faster, so you can focus on what you love.'
        },
    ]
    return (
        <div className='relative flex items-center flex-col w-[100vw] py-16 mt-10'>
            <h1 className='text-3xl font-normal font-Righteous'>Why Choose Barooom for <span className='text-4xl font-Righteous text-error'>Bartending Jobs</span>in Charlotte? </h1>
            <p className='pt-3 font-poppins text-gray-1 text-wrap max-w-[70%] text-center'>Charlotte is home to a thriving hospitality industry with bustling bars, restaurants, and event venues that are always on the lookout for talented staff. Whether you're located in the vibrant city of Charlotte, the cultural hub of Asheville, or the coastal town of Wilmington, there are plenty of opportunities for bartenders and bar staff in Charlotte.
            </p>
            {
                data.map((elem, ind) => (
                    <div className='relative w-screen mt-12'>
                        {
                            ind % 2 == 0 ?
                                <div className='w-[90%] flex'>
                                    <img src={elem.image} alt="" className='max-w-[60%] h-auto' />
                                    <div className='my-auto px-14'>
                                        <h1 className='text-3xl font-normal font-Righteous'>{elem.title}</h1>
                                        <p>{elem.description}</p>

                                    </div>
                                    {ind == 0 && <img src='/images/EstabLanding_05_Side.svg' className='absolute right-0 w-auto h-full' />}
                                </div>
                                :
                                <div className='flex my-10 ml-auto w-[90%]'>
                                    <div className='my-auto px-14'>
                                        <h1 className='text-3xl font-normal font-Righteous'>{elem.title}</h1>
                                        <p>{elem.description}</p>

                                    </div>
                                    <img src={elem.image} alt="" className='max-w-[60%] h-auto' />
                                </div>
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default EstabLanding05