import React from 'react'
import ContentWrapper from '../component/ContentWrapper/ContentWrapper'
import Footer from '../component/landing/Footer'
import ContactUsForm from '../component/ContactUsForm'
import style from "../component/ContentWrapper/ContentWrapper.module.css";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NewFooter from '../components/landing/NewFooter';

export default function ContactUs() {
    const user = useSelector((state) => state.user)
    return (
        <>
            <>
                <div
                    style={{
                        background: `url(/AboutBarrom/backgroundImg.png)`,
                        backgroundSize: "cover",
                    }}>
                    <header className={style.header}>
                        {user ? (
                            <></>
                        ) : (
                            <>
                                <Link to={"/login"} className={style.changeColor}>
                                    Login
                                </Link>
                                <Link to={"/signup"} className={style.changeColor}>
                                    Signup
                                </Link>
                            </>
                        )}
                    </header>
                    <Link to={"/"}>
                        <img
                            loading="lazy"
                            className={style.barrom}
                            src={"/LandingPageImages/Barrom.svg"}
                            alt=""
                        />
                    </Link>
                    <div className={style.lowerPart}>
                        <div className={style.backgroundImg}>
                            <h3>
                                Contact <span>Us</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className={style.middlePart}>
                    <ContactUsForm />
                </div>
            </>
            <NewFooter />
        </>)
}
