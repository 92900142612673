import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../config/configuration";
import Dropdown from "./Bartender/Dropdown";
import { Modal } from "@mui/material";
import NotificationEst from "./Establishment/NotificationEst";
import StripeSetupModal from "../Modals/StripeSetupModal";
import { useNavigate } from "react-router-dom";
import "./DashHeader.css";

export default function DashHeader({ leftContent, btn }) {
  const user = useSelector((state) => state.user.user);

  const data = useSelector((state) => state.global);
  const [localMessage, setLocalMessage] = useState({
    messageOne: data.setMessageOne,
    messageTwo: data.setMessageTwo,
  });

  const notifyState = useSelector((state) => state.isnotify.isUnread);
  const [showNotification, setShowNotification] = useState(false);
  const [profileDrop, setProfileDrop] = useState(false);

  const navigate = useNavigate();
  const [showPayAccSetup, setShowPayAccSetup] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLocalMessage({ ...localMessage, messageOne: data.setMessageOne });
    }, 2500);

    return () => clearTimeout(timeoutId);
  }, [localMessage.messageOne]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLocalMessage({ ...localMessage, messageTwo: data.setMessageTwo });
    }, 2500);

    return () => clearTimeout(timeoutId);
  }, [localMessage.messageTwo]);

  return (
    <>
      {/* DropDown Menu */}
      <Modal
        sx={{ minWidth: "1000px" }}
        open={profileDrop}
        onClose={() => setProfileDrop((prev) => !prev)}
      >
        <Dropdown />
      </Modal>

      {/* Notification Dropdown */}

      <div className="flex items-center justify-between gap-5 px-5 pb-2 border-b-2 border-solid max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
        <div className="flex gap-5 my-auto text-[30px] font-semibold tracking-tighter text-yellow-400 whitespace-nowrap leading-[58.8px]">
          {leftContent}
          {user && user.role === 1 && (
            <section className="flex flex-col gap-1">
              {user.connected_acc_setup == "0" && localMessage.messageOne && (
                <label
                  style={{
                    opacity: "0",
                    animation:
                      "slide 1s cubic-bezier(.23,1.74,.65,1.02) forwards",
                  }}
                  className="flex p-1 pl-2 text-sm font-light tracking-wide text-white rounded-lg cursor-pointer font-poppins bg-estab-back text-nowrap"
                >
                  <p onClick={() => setShowPayAccSetup(!showPayAccSetup)}>
                    Setup profile for accepting payments
                  </p>
                  <p
                    className="px-2"
                    onClick={() =>
                      setLocalMessage({
                        ...localMessage,
                        messageOne: !localMessage.messageOne,
                      })
                    }
                  >
                    {" "}
                    &#10005;
                  </p>
                </label>
              )}
              {user.connected_acc_setup == "1" && localMessage.messageOne && (
                <label className="flex p-1 pl-2 text-sm font-light tracking-wide text-white rounded-lg cursor-pointer font-poppins bg-estab-back text-nowrap">
                  <p>
                    Bank account verification under process. You can apply for
                    the gigs once the verification is completed.
                  </p>
                  <p
                    className="px-2"
                    onClick={() =>
                      setLocalMessage({
                        ...localMessage,
                        messageOne: !localMessage.messageOne,
                      })
                    }
                  >
                    {" "}
                    &#10005;
                  </p>
                </label>
              )}
              {!user.profileSetup && localMessage.messageTwo && (
                <label
                  style={{
                    opacity: "0",
                    animation:
                      "slide 1s 200ms cubic-bezier(.23,1.74,.65,1.02) forwards",
                  }}
                  className="flex p-1 pl-2 text-sm font-light tracking-wide text-black rounded-lg cursor-pointer font-poppins bg-yellow-1 text-nowrap"
                >
                  <p onClick={() => navigate("/profile?setup=true")}>
                    User profile details incomplete
                  </p>
                  <p
                    className="px-2 ml-auto"
                    onClick={() =>
                      setLocalMessage({
                        ...localMessage,
                        messageTwo: !localMessage.messageTwo,
                      })
                    }
                  >
                    {" "}
                    &#10005;
                  </p>
                </label>
              )}
            </section>
          )}

          {btn}
        </div>
        <div className="flex justify-between gap-5">
          <div className="relative h-fit">
            <img
              onClick={() => {
                setShowNotification(!showNotification);
              }}
              loading="lazy"
              alt="Notify Icon"
              src="/images/solar_bell-bold.png"
              className="shrink-0 self-start mt-2.5 aspect-square w-[42px] cursor-pointer"
            />
            {notifyState && (
              <img
                src="/images/Red_Dot.svg"
                className="absolute top-0 translate-y-2 right-2 "
                alt=""
              />
            )}
          </div>
          <div
            onClick={() => setProfileDrop((prev) => !prev)}
            style={{ cursor: "pointer" }}
            className="flex items-center justify-center gap-3 p-1 border-black rounded-lg shadow-lg"
          >
            <div>
              <img
                loading="lazy"
                alt="User Img"
                style={{ borderRadius: "10px" }}
                src={
                  user?.profileImg || user?.pic
                    ? `${config.cdnFront}/${user?.profileImg || user?.pic}`
                    : "/images/Dummy_User.jpg"
                }
                className="shrink-0 self-start size-[42px]"
              />
            </div>
            <button className="">
              <img
                src="/images/Down 2.svg"
                alt="Notify Icon"
                className="size-4"
              />
            </button>
          </div>
          {showNotification && (
            <NotificationEst
              setShowNotification={setShowNotification}
              showNotification={showNotification}
            />
          )}
        </div>
      </div>
      {showPayAccSetup && (
        <StripeSetupModal
          setModalShow={setShowPayAccSetup}
          ModalShow={showPayAccSetup}
        />
      )}
    </>
  );
}
