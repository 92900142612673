import React from "react";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import NewFooter from "../components/landing/NewFooter";

const NotFound = () => {

  return (
    <>
      <ContentWrapper
        type={"AboutSection"}
        title={["PAGE NOT", "FOUND"]}
        content={`<img src="/images/NotFoundLogo.svg" alt="" />`}
      />

      <NewFooter />
    </>
  );
};

export default NotFound;
