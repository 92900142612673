import React from 'react'
import PageHeaderNav from '../../pages/PageHeaderNav'

function SEOLandingBody() {
    return (
        <div className='h-[100vh] relative'>
            <div className='bg-bg-Header h-full bg-cover bg-no-repeat bg-center overflow-hidden rounded-br-[40%] rounded-bl-[80%]'>
                <PageHeaderNav />
                <div className='w-full pt-32 text-center'>
                    <h1 className='font-normal text-white font-Righteous text-7xl leading-[80px]'>Hire a Bartender in </h1>
                    <h1 className='font-normal text-yellow-1 font-Righteous text-7xl leading-[80px]'>Charlotte </h1>
                    <div className='w-full pt-4'>
                        <p className='text-2xl text-white text-wrap font-poppins'>Finding the right bartenders and hospitality staff can significantly </p>
                        <p className='text-2xl text-white text-wrap font-poppins'>impact the success of your bar, restaurant, or event venue.</p>
                        <p className='text-2xl text-white text-wrap font-poppins'>At Barooom, we understand this need deeply.</p>

                    </div>
                </div>
            </div>
            <img src="/images/Glass_Images.svg" alt="Glass_Img" className='absolute bottom-0 w-[35rem] h-fit max-sm:hidden' />
        </div>
    )
}

export default SEOLandingBody