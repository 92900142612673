import React, { useState } from "react";
import style from "./Footer.module.css";
import { Link, useNavigate } from "react-router-dom";
import { subscribe } from "../../axios/axios";
import { toast } from "react-toastify";
import { ensureCusEmail } from "../../utils/inputFormatter";

const NewFooter = () => {
  const openExternalLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const [email, setEmail] = useState("");
  const navigate = useNavigate()
  const handleSubscribe = () => {
    if (!email) {
      toast.error("Enter Email Id!");
      return;
    }
    if (!ensureCusEmail({ value: email })) {
      toast.error("Enter Valid Email ID!");
      return;
    }
    subscribe({ email })
      .then((res) => {
        toast.success(res.data.message);
        setEmail("");
      })
      .catch((err) => toast.error(err.data.message));
  };

  const links = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about-us" },
    { name: "Service", path: "/services" },
    { name: "Blog", path: "/blogs" },
    { name: "Contact", path: "/contact-us" },
  ]

  return (
    <div class="section8">
      <div class="sec8bg">
        <div class="sec8content">
          <div class="sec8handler">
            <img src="/images/sec1logo.png" alt="" />
            <div class="flex gap-5 pt-2">
              {links.map((elem, ind) => (<p key={ind} className={`pr-5 text-lg text-white cursor-pointer max-sm:text-sm max-sm:pr-2 ${ind < links.length - 1 && 'border-r'}`} onClick={() => navigate(elem.path)}>{elem.name}</p>))}
            </div>
            <h2 className="py-2 font-normal font-poppins">Subscribe To Our Newsletter</h2>
            <div class="email">
              <input
                type="text"
                placeholder="Enter Your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button class="emailbut" onClick={handleSubscribe}>
                Subscribe
              </button>
            </div>
            <div class="flex gap-5 h-12">
              <img src="/images/Facebook_Icon.svg" alt="" className="w-auto h-5 cursor-pointer" onClick={() => window.open('https://www.facebook.com/profile.php?id=61559697518079&mibextid=ZbWKwL', '_blank',)} />
              <img src="/images/Instagram_Icon.svg" alt="" className="w-auto h-5 cursor-pointer" onClick={() => window.open('https://www.instagram.com/barooombartenders?igsh=Z2ZlYTE0a3VianI3', '_blank',)} />
              <img src="/images/X_Icon.svg" alt="" className="w-auto h-5 cursor-pointer" onClick={() => window.open('https://x.com/barooombartend?t=GaexP7_k4m90rMbDQGUYlg&s=09', '_blank',)} />
            </div>
          </div>
          <div class="line"></div>
          <div className="flex justify-between w-full px-8 max-sm:flex-col max-sm:gap-2">
            <div className="flex gap-5">
              <p className="text-base font-normal text-white font-poppins max-sm:text-sm" style={{ marginBottom: 0 }}>
                Copyright © 2024 barooom. All Rights Reserved.
              </p>
            </div>
            <p className="text-base font-normal text-white font-poppins max-sm:text-sm">A Product By The Cottrill Group, Inc.</p>
            <div className="flex gap-8 ">
              <p className="text-white cursor-pointer font-poppins max-sm:text-sm" onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
              <p className="text-white cursor-pointer font-poppins max-sm:text-sm" onClick={() => navigate('/terms-conditions')}>Terms & Conditions</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFooter;
