import { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { Bounce, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import styles from "./BarEdit.module.css";
import { fetchBankDetails, fetchUserData, patchPayHourly, updateBartPayment } from "../../axios/axios";
import configuration from "../../config/configuration";
import { updateUser } from "../../redux/userSlice";
import { ensureNumber } from "../../utils/inputFormatter";
import { useNavigate } from "react-router-dom";
import AddBankDetailsModal from "../Modals/AddBankDetailsModal";

function BarEdit6() {
    const user = useSelector((state) => state.user.user);
    const [apiLoader, setapiLoader] = useState(false);
    const [hasChangedRates, setHasChangedRates] = useState(false);
    const [showBankModal, setShowBankModal] = useState(false);
    const navigate = useNavigate()

    const [localData, setLocalData] = useState({
        hourlyRates: "",
        accountDetail: {
            bankName: "",
            holderName: "",
            bankCode: "",
            accountNumber: "",
            routingNumber: "",
            accountType: "",
        },
    });

    const [bankdetails, setBankDetails] = useState({
        routing_number: "",
        last4: "",
        account_holder_name: "",
        bank_name: "",
    });

    const dispatch = useDispatch();

    const submitPayHourly = async () => {
        setapiLoader(true);
        patchPayHourly({
            hourlyRates: localData.hourlyRates,
            token: user.token,
        })
            .then((result) => {
                toast.success(result?.data.message)
                setapiLoader(false);
                navigate("/profile");
            })
            .catch((err) => {
                toast.error(err.message, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setapiLoader(false);

                console.log("err", err);
            });
    };

    const getUserData = () => {
        fetchUserData({ token: user?.token })
            .then((result) => {
                const { hourlyRates } = result.data.data;
                setLocalData({ hourlyRates: hourlyRates || '' });
            })
            .catch((err) => console.log(err));
    };
    const getUserBankDetails = () => {
        fetchBankDetails({ token: user?.token })
            .then((result) => {
                const { routing_number, last4, account_holder_name, bank_name } = result.data.data?.[0];
                setBankDetails({ routing_number, last4, account_holder_name, bank_name })
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getUserData();
        getUserBankDetails();
    }, []);

    const changeInputHandler = ({ name, value }) => {
        let x = { ...localData };
        x[name] = value;
        setLocalData(x);
        if (!hasChangedRates) setHasChangedRates(true)
    };


    return (
        <div className="flex relative justify-center items-center self-center px-16 py-12 mt-14 w-full rounded-3xl bg-white bg-opacity-80 max-w-[1599px] max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col items-center mt-7 mb-1.5 max-w-full w-[1213px]">
                <div
                    className={`text-2xl leading-9 text-center text-black whitespace-nowrap} ${styles.header}`}>
                    Payment setup
                </div>
                <div className="self-stretch mt-1.5 text-base text-center text-neutral-600 max-md:max-w-full">
                    Establishments will see your expected hourly rate, but their posting
                    may offer a higher/lower offer for a gig, you choose whether to accept
                    or not at time of posting.
                </div>
                <div className="flex flex-col mt-16 max-w-full text-xs w-[639px] max-md:mt-10">
                    <div className="flex gap-4 justify-between px-5 py-3.5 bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:flex-wrap max-md:max-w-full">
                        <img
                            loading="lazy"
                            src="/images/completeProfile/tick-circle-solid.png"
                            className="my-auto aspect-square w-[23px]"
                        />
                        <div className="flex flex-col flex-1 max-md:max-w-full">
                            <div className="text-black text-start max-md:max-w-full">
                                Setup Hourly Rates <span className="text-error">*</span>
                            </div>

                            <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
                                $
                                <input
                                    style={{ width: "60px" }}
                                    className="border-none outline-none w-[100]"
                                    type="text"
                                    name="hourlyRates"
                                    value={localData.hourlyRates}
                                    onChange={(e) =>
                                        changeInputHandler({
                                            name: e.target.name,
                                            value: ensureNumber({
                                                value: e.target.value,
                                                maxLength: 8,
                                            }),
                                        })
                                    }
                                />
                                /hr
                            </div>
                        </div>
                        <label htmlFor="CashIcon" className={`flex flex-col items-center justify-center m-auto rounded-full h-14 w-14 ${hasChangedRates ? 'bg-[#9becaf] cursor-pointer' : "bg-[#eee9e9] cursor-not-allowed "}`} onClick={() => { hasChangedRates && submitPayHourly() }}>
                            {
                                hasChangedRates ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#00b12c" d="M3 6v12h10.32a6.4 6.4 0 0 1-.32-2H7a2 2 0 0 0-2-2v-4c1.11 0 2-.89 2-2h10a2 2 0 0 0 2 2v.06c.67 0 1.34.12 2 .34V6zm9 3c-1.7.03-3 1.3-3 3s1.3 2.94 3 3c.38 0 .77-.08 1.14-.23c.27-1.1.72-2.14 1.83-3.16C14.85 10.28 13.59 8.97 12 9m9.63 3.27l-3.87 3.9l-1.35-1.37L15 16.22L17.75 19l5.28-5.32z" /></svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#cecece" d="M3 6v12h10.32a6.4 6.4 0 0 1-.32-2H7a2 2 0 0 0-2-2v-4c1.11 0 2-.89 2-2h10a2 2 0 0 0 2 2v.06c.67 0 1.34.12 2 .34V6zm9 3c-1.7.03-3 1.3-3 3s1.3 2.94 3 3c.38 0 .77-.08 1.14-.23c.27-1.1.72-2.14 1.83-3.16C14.85 10.28 13.59 8.97 12 9m9.63 3.27l-3.87 3.9l-1.35-1.37L15 16.22L17.75 19l5.28-5.32z" /></svg>
                            }
                            <p className={`text-[8px] ${hasChangedRates ? 'text-[#00b12c]' : 'text-[#cecece]'}`}>Set Rates</p>
                        </label>
                    </div>

                    <div className="px-5 py-3.5 mt-8 bg-white border-gray-200 border-solid border-[0.922px] relative rounded-xl max-md:max-w-full pb-12">
                        <section className="w-full h-full bg-white rounded-xl">

                            <section className="flex items-center w-full h-full gap-8 pb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25.72" height="24" viewBox="0 0 1920 1792"><path fill="#000" d="m960 0l960 384v128h-128q0 26-20.5 45t-48.5 19H197q-28 0-48.5-19T128 512H0V384zM256 640h256v768h128V640h256v768h128V640h256v768h128V640h256v768h59q28 0 48.5 19t20.5 45v64H128v-64q0-26 20.5-45t48.5-19h59zm1595 960q28 0 48.5 19t20.5 45v128H0v-128q0-26 20.5-45t48.5-19z" /></svg>
                                <label htmlFor="bank_Name">
                                    <h1 className="text-xl">
                                        {bankdetails.bank_name}
                                    </h1>
                                    <p>A/c No. - {bankdetails.last4}</p>
                                </label>
                            </section>
                            <hr className="py-1" />
                            <section>
                                <label htmlFor="Holder_Name" className="flex gap-5">
                                    <p className="w-1/4">Account Holder Name: </p>
                                    <p className="text-gray-1">{bankdetails.account_holder_name || '-'}</p>
                                </label>
                                <label htmlFor="Routing_Number" className="flex gap-5">
                                    <p className="w-1/4">Routing Number: </p>
                                    <p className="text-gray-1">{bankdetails.routing_number || '-'}</p>
                                </label>
                            </section>
                        </section>

                        <section className="absolute bottom-0 left-0 flex items-center w-full gap-2 py-2 pl-4 bg-[#d7ebec] rounded-b-xl">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#00b12c" d="m23 12l-2.44-2.78l.34-3.68l-3.61-.82l-1.89-3.18L12 3L8.6 1.54L6.71 4.72l-3.61.81l.34 3.68L1 12l2.44 2.78l-.34 3.69l3.61.82l1.89 3.18L12 21l3.4 1.46l1.89-3.18l3.61-.82l-.34-3.68zm-13 5l-4-4l1.41-1.41L10 14.17l6.59-6.59L18 9z" /></svg>
                            <p className="text-[#9e9e9e]">Primary bank account for transaction.</p>
                        </section>
                    </div>
                    <label htmlFor="UpdateBank" className="flex items-center gap-2 py-2 pl-4 cursor-pointer" onClick={() => setShowBankModal(!showBankModal)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#000" d="M17 14h2v3h3v2h-3v3h-2v-3h-3v-2h3zM11.5 1L21 6v2H2V6zm4.5 9h3v2.08L18 12c-.7 0-1.37.12-2 .34zM2 22v-3h10.08c.19 1.14.71 2.17 1.45 3zm8-12h3v4.68c-.46.69-.78 1.47-.92 2.32H10zm-6 0h3v7H4z" /></svg>
                        <p>Update Bank Details</p>
                    </label>
                </div>
            </div>
            {showBankModal && <AddBankDetailsModal showModal={showBankModal} setShowModal={setShowBankModal} hourlyRates={localData.hourlyRates} />}
        </div>
    );
}
export default BarEdit6;
