import React, { useEffect, useState } from "react";
import { BartenderAvailTodayApi } from "../../axios/axios";
import configuration from "../../config/configuration";
import style from "./dashboard.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
function BartenderAvailToday() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [barset, setBarSet] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const fetchAvailBarToday = () => {
    BartenderAvailTodayApi({
      token: user?.token,
      page: barset.page,
      search: barset.search,
    })
      .then((result) => {
        let res = result?.data?.data;
        let x = { ...barset };

        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setBarSet(x);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    fetchAvailBarToday();
  }, []);

  return (
    <div className=" max-md:flex-wrap flex-2">
      <div className="min-h-full bg-white shadow-2xl rounded-3xl ">
        <div className="p-4 ">
          <div
            className={`text-lg text-black font-normal font-Righteous ${style.header}`}
          >
            <span
              className={`text-establishment-red font-normal font-Righteous ${style.header}`}
            >
              {barset?.history?.length} Bartenders &nbsp;
            </span>
            that are available today
          </div>
          <div className="mt-10">
            {barset.history.map((elem, index) => {
              const convertTo12HourFormat = (time) => {
                const [hour, minute] = time.split(":");
                const ampm = hour >= 12 ? "PM" : "AM";
                const adjustedHour = hour % 12 || 12;
                return `${adjustedHour}:${minute} ${ampm}`;
              };

              return (
                <div
                  style={{
                    boxShadow: "0px 0px 21.461px 0px rgba(0, 0, 0, 0.10)",
                  }}
                  key={`Bar_${elem._id}_${index}`}
                  className="flex flex-col px-2 pt-1.5 pb-3 mt-2  rounded-xl border-l-4 border-red-500 border-solid max-md:mr-2.5 max-md:max-w-full"
                >
                  <div className="flex gap-2.5 justify-center items-center">
                    <div>
                      <img
                        loading="lazy"
                        src={
                          elem?.UserDetails?.[0]?.pic || elem?.profileImg
                            ? `${configuration.cdnFront}/${
                                elem?.UserDetails?.[0]?.pic || elem?.profileImg
                              }`
                            : "/images/Dummy_User.jpg"
                        }
                        alt=""
                        className="self-start shrink-0 size-14 rounded-xl"
                      />
                    </div>
                    <div className="flex flex-col gap-[0.1rem] grow shrink-0 basis-0 w-fit">
                      <div className="flex justify-between gap-5">
                        <div
                          className="flex flex-col gap-1"
                          onClick={() =>
                            navigate(`/bartender-details/${elem?.uid}`)
                          }
                        >
                          <div className="items-center flex-auto text-sm font-medium tracking-tighter text-black font-poppins">
                            {elem?.UserDetails?.[0]?.name}
                          </div>
                          <div className="flex-auto items-center text-[10px] text-black font-poppins w-fit">
                            {elem?.isCompleteAvail ? (
                              <p className="bg-green-300 border-[1px] border-green-600 rounded-[25px] px-[4px]">
                                Completely Available
                              </p>
                            ) : (
                              <p className="bg-yellow-300 border-[1px] border-yellow-600 rounded-[25px] px-[4px]">
                                Partialy Available
                              </p>
                            )}
                          </div>
                        </div>
                        <div
                          className="justify-center cursor-pointer whitespace-nowrap text-neutral-500"
                          onClick={() =>
                            navigate(`/dashboard/chat/`, {
                              state: { uid: elem?.uid },
                            })
                          }
                        >
                          <img
                            loading="lazy"
                            src="/images/completeProfile/yellowChat.png"
                            className="self-start shrink-0 aspect-square"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="text-[12px] text-neutral-400">
                        {elem?.shiftTime && elem?.shiftTime.length > 0 && (
                          <p>
                            {" "}
                            Shift Time :{" "}
                            {convertTo12HourFormat(
                              elem.shiftTime[0]?.starttime
                            )}{" "}
                            -{convertTo12HourFormat(elem.shiftTime[0]?.endtime)}
                          </p>
                        )}
                      </div>
                      <div
                        className="flex-auto my-auto text-[11px] cursor-pointer"
                        onClick={() =>
                          navigate(`/bartender-details/${elem?.uid}`)
                        }
                      >
                        View Profile
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BartenderAvailToday;
