import dayjs from "dayjs";

export const upconvertremainingtime = (date) => {
  const diff = dayjs().diff(dayjs(date), "second");
  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const seconds = diff % 60;
  return `${
    Math.abs(hours) < 1 ? "" : `${Math.abs(hours)} hr${hours === 1 ? "" : "s"},`
  } ${Math.abs(minutes)} min${minutes === 1 ? "" : "s"}`;
};

export const convertremainingtime = (date) => {
  const diff = dayjs().diff(dayjs(date), "second");
  const hours = Math.floor(diff / 3600);
  const minutes = Math.floor((diff % 3600) / 60);
  const seconds = diff % 60;
  return `${
    hours < 1 ? "" : `${hours} hr${hours === 1 ? "" : "s"},`
  } ${minutes} min${minutes === 1 ? "" : "s"}`;
};

export const RemainingTime = (end_date) => {
  var currentDate = new Date();
  var endDate = new Date(end_date);
  var timeDifference = endDate - currentDate;
  var days = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return days;
};

export const handleFloat = (value) => {
  if (value != "" && value?.includes(".")) {
    return parseFloat(value).toFixed(2);
  } else {
    return value;
  }
};

export const formatNumber = (num) => {
  if (num >= 1e7) {
    return (num / 1e7).toFixed(1) + "L";
  } else if (num >= 1e5) {
    return (num / 1e5).toFixed(1) + "L";
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + "K";
  } else {
    return handleFloat(num?.toString());
  }
};

export const displayFileName = ({ fileName }) => {
  let name = fileName.split(".");
  return name[0].substring(0, 10) + "." + name[1];
};
