import { useEffect, useState } from "react";
import styles from "./BarEdit.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { Button } from "antd";
import {
  fetchUserData,
  updateBartBasic,
  updateBartDesig,
} from "../../axios/axios";
import { updateUser } from "../../redux/userSlice";
import { ensureNumber, ensureString } from "../../utils/inputFormatter";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function BarEdit2() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [errormsg, setErrorMsg] = useState({});
  const changeInputHandler = ({ name, value }) => {
    let x = { ...localData };
    if (Array.isArray(x[name])) {
      if (x[name].includes(value)) {
        x[name] = x[name].filter((item) => item !== value);
      } else {
        x[name].push(value);
      }
    } else {
      x[name] = [value];
    }
    console.log(x);

    setLocalData(x);
  };
  const [localData, setLocalData] = useState({
    designation: "",
  });

  const submitHandler = () => {
    setApiLoader(true)

    updateBartDesig({ designation: localData.designation, token: user.token })
      .then((result) => {
        toast.success(result?.data?.message);
        setApiLoader(false);
        navigate("/profile");
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setApiLoader(false);
        console.log("err", err);
      });
  };

  const getUserData = () => {
    fetchUserData({ token: user?.token })
      .then((result) => {
        const userData = result.data.data;
        setLocalData({ designation: userData?.designation });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="flex flex-col max-w-full w-[1299px]">
      <div
        className={`self-stretch text-2xl leading-9 text-center text-black} `}>
        Your Designation
      </div>
      <div className="mt-2.5 text-base whitespace-nowrap text-neutral-600">
        Select all that apply.
      </div>

      <div className="px-5 mt-10 max-w-[585px] ml-auto mr-auto">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div
            className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full"
            style={{ cursor: "pointer" }}
            onClick={() =>
              changeInputHandler({
                name: "designation",
                value: "bartender",
              })
            }>
            <div
              className={
                localData.designation?.includes("bartender")
                  ? `flex flex-col px-6 py-7 mx-auto text-lg font-medium tracking-widest leading-8 text-center text-red-500 capitalize whitespace-nowrap rounded-2xl border-solid bg-red-500 bg-opacity-20 border-[3.032px] border-[color:var(--ff-3333,#F33)] h-[150px] w-[150px] max-md:px-5 max-md:mt-10`
                  : `flex flex-col px-3 py-5 mx-auto text-lg font-medium tracking-widest leading-8 text-center capitalize whitespace-nowrap bg-white rounded-2xl border-solid shadow-lg border-[1.516px] border-zinc-400 h-[150px] text-zinc-400 w-[150px] max-md:px-5 max-md:mt-10`
              }>
              <img
                loading="lazy"
                src={
                  localData.designation?.includes("bartender")
                    ? "/images/completeProfile/cocktail-glass.png"
                    : "/images/completeProfile/cocktail-glass-gray.png"
                }
                className="self-center w-15 aspect-[1.01]"
              />
              <div className="mt-1">Bartender</div>
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full"
            onClick={() =>
              changeInputHandler({
                name: "designation",
                value: "bar-back",
              })
            }>
            <div
              className={
                localData.designation?.includes("bar-back")
                  ? `flex flex-col px-6 py-7 mx-auto text-lg font-medium tracking-widest leading-8 text-center text-red-500 capitalize whitespace-nowrap rounded-2xl border-solid bg-red-500 bg-opacity-20 border-[3.032px] border-[color:var(--ff-3333,#F33)] h-[150px] w-[150px] max-md:px-5 max-md:mt-10`
                  : `flex flex-col px-3 py-5 mx-auto text-lg font-medium tracking-widest leading-8 text-center capitalize whitespace-nowrap bg-white rounded-2xl border-solid shadow-lg border-[1.516px] border-zinc-400 h-[150px] text-zinc-400 w-[150px] max-md:px-5 max-md:mt-10`
              }>
              <img
                style={{ color: "red" }}
                loading="lazy"
                src={!localData.designation?.includes("bar-back") ? "/images/completeProfile/barback.png" : '/images/bar_back_red.svg'}
                className="self-center w-20 aspect-[1.01]"
              />
              <div className="mt-2">Bar-Back</div>
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full"
            onClick={() =>
              changeInputHandler({
                name: "designation",
                value: "server",
              })
            }>
            <div
              className={
                localData.designation?.includes("server")
                  ? `flex flex-col px-6 py-7 mx-auto text-lg font-medium tracking-widest leading-8 text-center text-red-500 capitalize whitespace-nowrap rounded-2xl border-solid bg-red-500 bg-opacity-20 border-[3.032px] border-[color:var(--ff-3333,#F33)] h-[150px] w-[150px] max-md:px-5 max-md:mt-10`
                  : `flex flex-col px-3 py-5 mx-auto text-lg font-medium tracking-widest leading-8 text-center capitalize whitespace-nowrap bg-white rounded-2xl border-solid shadow-lg border-[1.516px] border-zinc-400 h-[150px] text-zinc-400 w-[150px] max-md:px-5 max-md:mt-10`
              }>
              <img
                loading="lazy"
                src={!localData.designation?.includes("server") ? "/images/completeProfile/shutterstock.png" : '/images/server_red.svg'}
                className="self-center w-20 aspect-[1.01]"
              />
              <div className="mt-2">Server</div>
            </div>
          </div>
        </div>
      </div>

      <Button
        loading={apiLoader}
        className="justify-center self-center px-5 py-5 cursor-pointer mt-16 w-[60%] text-sm text-center text-white bg-amber-300  rounded-[92.207px] max-md:mt-10 max-md:max-w-full"
        onClick={submitHandler}>
        Save
      </Button>
    </div >
  );
}

export default BarEdit2;
