import React, { useState } from "react";
import styles from "../../dashboard.module.css";
import { Link, useNavigate } from "react-router-dom";
import { postJob } from "../../../../axios/axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function Jp3({ jobdata, setJobData, setActiveJTab, activejtab }) {
  const [showModal, setShowModal] = React.useState(false);
  const user = useSelector((state) => state.user.user);
  const [currentDuties, setCurrentDuties] = useState("");
  const [currentbenifits, setCurrentBenefits] = useState("");
  const navigate = useNavigate();
  const handleSaveNext = () => {
    setShowModal(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (currentDuties.trim()) {
        setJobData((prevState) => ({
          ...prevState,
          dutiesRes: [...prevState.dutiesRes, currentDuties.trim()],
        }));
        setCurrentDuties("");
      }
    } else if (e.key === "Backspace" && currentDuties === "") {
      e.preventDefault();
      setJobData((prevState) => {
        const updatedDuties = [...prevState.dutiesRes];
        updatedDuties.pop();
        return { ...prevState, dutiesRes: updatedDuties };
      });
    }
  };

  const handleKeyDownBenifit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (currentbenifits.trim()) {
        setJobData((prevState) => ({
          ...prevState,
          benifits: [...prevState.benifits, currentbenifits.trim()],
        }));
        setCurrentBenefits("");
      }
    } else if (e.key === "Backspace" && currentbenifits === "") {
      e.preventDefault();
      setJobData((prevState) => {
        const updatedBenifits = [...prevState.benifits];
        updatedBenifits.pop();
        return { ...prevState, benifits: updatedBenifits };
      });
    }
  };

  const handlePostJob = () => {
    setShowModal(false);
    postJob({ token: user?.token, jobdata })
      .then((res) => {
        toast.success(res.data.message);
        navigate("/dashboard");
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <div className="flex flex-col items-center bg-white shadow-lg rounded-[25px] p-4 h-full overflow-auto no-scrollbar">
      <div className="flex flex-col items-center">
        <p className="text-2xl font-righteous">Post A Job</p>
        <p className="text-sm font-poppins text-subtext">Add New Job</p>
      </div>
      <div className="w-[90%] flex flex-col gap-4">
        <div className="flex gap-4 items-start px-5 pt-3.5 pb-5 mt-9 min-h-40  text-xs bg-white rounded-3xl border border-gray-200 border-solid max-md:flex-wrap">
          <img
            loading="lazy"
            src="/images/completeProfile/pen.png"
            className="shrink-0 aspect-[1.04] w-[23px]"
          />
          <div className="flex flex-col text-left grow shrink-0 basis-0 w-fit max-md:max-w-full">
            <div className="text-black max-md:max-w-full">
              Duties & Responsibilities (optional)
            </div>
            <div className="mt-2 text-zinc-500 max-md:max-w-full">
              <ul className="flex flex-wrap gap-2 p-2">
                {jobdata.dutiesRes.map((skill, index) => (
                  <li
                    key={`duties_${index}`}
                    className="bg-gray-500 py-1 px-[5px] rounded-full text-black">
                    {skill}
                  </li>
                ))}
              </ul>
              <input
                className="w-full py-2 border-none outline-none"
                name="w3review"
                rows="4"
                cols="50"
                value={currentDuties}
                onChange={(e) => {
                  setCurrentDuties(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                placeholder="Type the duties or responsibilite and press Enter to register the entity"></input>
              <div className="flex justify-end w-full px-5 text-base">
                {jobdata?.dutiesRes.length}/20
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 items-start px-5 pt-3.5 pb-5 mt-5 min-h-40  text-xs bg-white rounded-3xl border border-gray-200 border-solid max-md:flex-wrap">
          <img
            loading="lazy"
            src="/images/completeProfile/pen.png"
            className="shrink-0 aspect-[1.04] w-[23px]"
          />
          <div className="flex flex-col h-full text-left grow shrink-0 basis-0 w-fit max-md:max-w-full">
            <div className="text-black max-md:max-w-full">
              Benefits (optional)
            </div>
            <div className="mt-2 text-zinc-500 max-md:max-w-full">
              <ul className="flex flex-wrap gap-2 p-2">
                {jobdata.benifits.map((skill, index) => (
                  <li
                    key={`benifits_${index}`}
                    className="bg-gray-500 py-1 px-[5px] rounded-full text-black">
                    {skill}
                  </li>
                ))}
              </ul>
              <input
                className="w-full py-2 border-none outline-none"
                name="w3review"
                value={currentbenifits}
                onChange={(e) => {
                  setCurrentBenefits(e.target.value);
                }}
                onKeyDown={(e) => handleKeyDownBenifit(e)}
                placeholder="Type the Benifit and press Enter to register the benifit"></input>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-red-600 w-[40%] rounded-[40px] min-h-16 mt-8 flex justify-center items-center text-white text-lg bottom-10 hover:cursor-pointer"
        onClick={() => handleSaveNext()}>
        Post Job
      </div>
      <>
        {showModal ? (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-3xl mx-auto my-6">
                <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex flex-col px-7 py-5 text-center bg-white rounded-[60px] max-w-[478px] max-md:px-5">
                    <div className="text-[16px] font-medium leading-7 text-black max-md:max-w-full">
                      You are ready to post this gig! <br />
                      And BAROOOM thanks you for utilizing our Services!
                    </div>
                    <div className="flex gap-2.5 self-center mt-7 text-[15px] capitalize max-md:mt-10">
                      <button
                        onClick={() => setShowModal(false)}
                        className="justify-center px-9 py-3.5 text-black whitespace-nowrap bg-white border border-amber-300 border-solid rounded-[110px] max-md:px-5">
                        Cancel
                      </button>
                      <button
                        onClick={() => handlePostJob()}
                        className="justify-center px-9 py-3.5 text-white bg-red-500 rounded-[110px] max-md:px-5">
                        Post Job
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
          </>
        ) : null}
      </>
    </div>
  );
}

export default Jp3;
