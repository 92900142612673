import { useEffect } from "react";
import BartenderWrapperDash from "../components/dashboard/WrapperDash";
import { useDispatch, useSelector } from "react-redux";
import WrapperDashEst from "../components/authWrapper/WrapperDashEst";
import { generateToken } from "../firebase";
import { addtoken } from "../redux/tokenslice";
import { updateBartenderGeoLocation } from "../axios/axios";

function Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const updateNotifyToken = async () => {
    try {
      const notifytoken = await generateToken(user?.token);
      dispatch(addtoken(notifytoken));
    } catch (error) {
      console.error(error);
    }
  };

  const updateLocation = () => {
    if (navigator.geolocation && user.role === 1) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;

          updateBartenderGeoLocation({ latitude, longitude, token: user.token })
            .then((res) => {})
            .catch((err) => console.log("Error at setting GEO Location"));
        },
        (err) => {
          console.log("Error fetching Location", err);
        }
      );
    }
  };

  useEffect(() => {
    updateNotifyToken();
    updateLocation();
  }, []);

  return (
    <>
      {user && (
        <>
          {user.role === 1 && <BartenderWrapperDash />}
          {user.role === 2 && <WrapperDashEst />}
        </>
      )}
    </>
  );
}
export default Dashboard;
