import React from 'react'

function SEOLadingSection2() {
    return (
        <div className='h-[100vh] relative flex justify-center items-center px-40 max-lg:px-10 '>
            <img src="/images/Side_Graphic.svg" alt="Bartender_Image" className='w-auto h-[25rem]' />
            <div className=''>
                <h1 className='text-4xl font-normal font-Righteous'>Why Hiring the <span className='text-4xl font-Righteous text-error'>Right Talent</span> Matters?</h1>
                <p className='pt-3  font-poppins text-gray-1'>As Charlotte's premier platform for hiring top-quality bartenders, we simplify the process of connecting you with skilled professionals who can enhance your establishment’s service and customer experience.</p>
                <p className='pt-2 font-poppins text-gray-1'>In the bustling world of hospitality, the right talent can make all the difference. A skilled bartender does more than just mix drinks; they create an atmosphere, engage with guests, and represent your brand. Here’s why hiring a qualified bartender is essential:</p>
                <div className='flex flex-col gap-4 pt-4'>
                <label className='flex'> 
                    <img src="/images/Customer_Icon.svg" alt="Icon" className='w-auto h-16' />
                    <p className='font-normal font-poppins text-gray-1 '>
                        <span className='font-bold text-black font-poppins'>Customer Experience:</span> The bartender is often the first point of contact for your guests. A friendly, knowledgeable bartender can turn a good night into a great one by providing exceptional service and memorable interactions.
                    </p>
                </label>
                <label className='flex'> 
                    <img src="/images/Customer_Icon.svg" alt="Icon" className='w-auto h-16' />
                    <p className='font-normal font-poppins text-gray-1 '>
                        <span className='font-bold text-black font-poppins'>Customer Experience:</span> The bartender is often the first point of contact for your guests. A friendly, knowledgeable bartender can turn a good night into a great one by providing exceptional service and memorable interactions.
                    </p>
                </label>
                <label className='flex'> 
                    <img src="/images/Customer_Icon.svg" alt="Icon" className='w-auto h-16' />
                    <p className='font-normal font-poppins text-gray-1 '>
                        <span className='font-bold text-black font-poppins'>Customer Experience:</span> The bartender is often the first point of contact for your guests. A friendly, knowledgeable bartender can turn a good night into a great one by providing exceptional service and memorable interactions.
                    </p>
                </label>

                </div>
            </div>
            <img src="/images/Bear_Glass.svg" alt="Bear_Glass" className='absolute mt-auto right-0 h-[25rem]' />
        </div>
    )
}

export default SEOLadingSection2