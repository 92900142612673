import React, { useState } from "react";
import { toast } from "react-toastify";
import { ContactUsAPI, createEarlyRegi } from "../axios/axios";
import {
  ensureCusEmail,
  ensureEmail,
  ensureNumber,
} from "../utils/inputFormatter";
import { Button } from "antd";

function ContactUsForm() {
  const [data, setdata] = useState({
    email: "",
    fullName: "",
    phoneNo: "",
    comments: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setdata({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);
    if (validateFormData(data)) {
      ContactUsAPI({ data })
        .then((res) => {
          toast.success(res.data.message);
          setdata({
            email: "",
            fullName: "",
            phoneNo: "",
            comments: "",
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err?.message);
        });
    } else {
      setLoading(false);
    }
  };

  const validateFormData = (data) => {
    let isValid = true;
    if (!data.fullName) {
      toast.error("Full Name is required");
      return false;
    }

    if (!data.email) {
      toast.error("Email is required");
      return false;
    }
    // else if (ensureCusEmail({ value: data.email })) {
    //   toast.error("Email is invalid");
    //   return false;
    // }

    if (!data.phoneNo) {
      toast.error("Phone Number is required");
      return false;
    } else if (!/^\d+$/.test(data.phoneNo)) {
      toast.error("Phone Number is invalid");
      return false;
    } else if (data.phoneNo.length < 10) {
      toast.error("Phone Number must be at least 10 digits long");
      return false;
    }

    return isValid;
  };
  return (
    <div class="sec7bg">
      <div class="sec7main">
        <div class="sec7mid">
          <div class="sec7white">
            <div class="sec7content">
              <div class="sec7top">
                <h1>Contact our friendly team</h1>
                <h5>Let us know how we can help.</h5>
              </div>
              <div class="labels">
                <div class="inputbox">
                  <label for="emailname">
                    Full Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    id="fullName"
                    value={data.fullName}
                    onChange={(e) => handleChange(e)}
                    name="fullName"
                    required
                  />
                </div>
                <div class="inputbox">
                  <label for="emailname">
                    Email Address<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    id="email"
                    value={data.email}
                    onChange={(e) => {
                      const value = ensureEmail({
                        value: e.target.value,
                      });
                      setdata({
                        ...data,
                        email: value,
                      });
                    }}
                    name="email"
                    required
                  />{" "}
                </div>
                <div class="inputbox">
                  <label for="emailname">
                    Phone Number<span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    id="phoneNo"
                    value={data.phoneNo}
                    onChange={(e) => {
                      const value = ensureNumber({
                        value: e.target.value,
                        maxLength: 10,
                      });
                      setdata({
                        ...data,
                        phoneNo: value,
                      });
                    }}
                    min={10}
                    name="phoneNo"
                    required
                  />{" "}
                </div>
              </div>

              <div class="labels">
                <div class="inputbox">
                  <label for="emailname">How can we help?</label>
                  <input
                    type="text"
                    placeholder=""
                    id="comments"
                    value={data.comments}
                    onChange={(e) => handleChange(e)}
                    name="comments"
                    required
                  />{" "}
                </div>
              </div>
              <div class="sec7btn">
                <button
                  onClick={() => handleSubmit()}
                  disabled={loading}
                  style={{ cursor: "pointer" }}>
                  Submit <img src="/images/arrow.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsForm;
