import React, { useEffect, useState } from "react";
import styles from "../styles/login.module.css";

import { Link, useNavigate } from "react-router-dom";
import {
  appleLoginApi,
  googleLoginapi,
  resendOtpApi,
  signInApi,
  UpdateNoitfyToken,
} from "../axios/axios";
import { Alert } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import NotAuthenticatedLayout from "../layouts/NotAuthenticatedLayout";
import { useDispatch } from "react-redux";
import { login } from "../redux/userSlice";
import { ensureEmail } from "../utils/inputFormatter";
import { Button } from "antd";
import { Bounce, toast } from "react-toastify";
import AppleLogin from "react-apple-login";
import config from "../config/configuration";
import { useGoogleLogin} from "@react-oauth/google";
import useSwipeHandler from "../components/useSwipeHandler";

export default function Login() {
  const [activeTab, setActiveTab] = useState(1);

  const [error, setError] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [notifytoken, setNotifyToken] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
    role: activeTab,
    // keepLogged: false,
  });
  const swipeHandlers = useSwipeHandler({
    setTab: setActiveTab,
    maxCount: 2,
    lessCount: 1,
  });

  const googleSuccess = (res) => {
    googleLoginapi({ access_token: res.access_token, role: user.role })
      .then((response) => {
        let usr = {
          ...response.data.data.user,
          token: response.data.data.token,
        };

        dispatch(login(usr));
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  const googleFailure = (err) => {
    toast.error("Google Login Failed", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const Glogin = useGoogleLogin({
    onSuccess: googleSuccess,
    onError: googleFailure,
  });

  const handleInputChange = (e) => {
    if (e.target.name === "email" || e.target.name === "password") {
      e.target.value = ensureEmail({ value: e.target.value });
    }
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const loginFunc = async () => {
    try {
      setLoading(true);
      const response = await signInApi(user);
      toast.success(response?.data.message);
      setLoading(false);
      let usr = { ...response.data.data.user, token: response.data.data.token };
      if (notifytoken && notifytoken != "") {
        UpdateNoitfyToken({
          token: response.data.data.token,
          notifytoken: notifytoken,
        });
      }

      if (response.data.data.user.account_status === 0) {
        await resendOtpApi({ refId: response.data.data.user._id });
        navigate("/verify", { state: response.data.data.user._id });
      } else {
        dispatch(login(usr));
      }
    } catch (error) {
      setLoading(false);
      console.error("Login error:", error);

      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const goToForgotPass = () => {
    navigate("/forget-password");
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const appleCallback = (response) => {
    if (response.error) {
      toast.error("Apple Login Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      return;
    }

    appleLoginApi({ ...response, role: user.role, referal_code: "" })
      .then((result) => {
        let usr = { ...result.data.data.user, token: result.data.data.token };
      
        if (notifytoken && notifytoken != "") {
          UpdateNoitfyToken({
            token: response.data.data.token,
            notifytoken: notifytoken,
          });
        }

        dispatch(login(usr));

      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  useEffect(() => {
    setUser((prev) => ({
      ...prev,
      role: activeTab,
    }));

    setError("");
  }, [activeTab]);

  return (
    <>
      <NotAuthenticatedLayout>
        <div
          className={styles.container}
          style={{
            backgroundImage:
              activeTab === 1
                ? "url('/images/background-Image.png')"
                : `url("/images/BackgroundImage.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          {...swipeHandlers}>
          <div className={styles.dashboardcontainer}>
            <div className={styles.upperImg}>
              <img src="/images/barooom.png" />
            </div>
            <div className="flex flex-col items-center bg-white border-solid border-[5.241px] border-[color:var(--ffcc-42,#FFCC42)] rounded-[41.924px] w-[100%]">
              <div className={styles.header}>Sign In</div>
              <div className="flex flex-col items-center">
                <div className="flex gap-2 justify-center px-1.5 py-1 mt-3 text-base shadow-[0_1px_5px_rgb(0,0,0,0.2)] leading-6 text-center whitespace-nowrap bg-white rounded-[132.798px]">
                  <div
                    style={{ flex: 1, cursor: "pointer" }}
                    onClick={() => setActiveTab(1)}
                    className={
                      activeTab === 1
                        ? `justify-center px-9 py-3 font-medium text-white bg-red-500 rounded-[132.798px] max-md:px-5`
                        : `justify-center px-9 py-3 max-md:px-5 text-neutral-400`
                    }>
                    Bartender
                  </div>

                  <div
                    style={{ flex: 1, cursor: "pointer" }}
                    onClick={() => setActiveTab(2)}
                    className={
                      activeTab === 2
                        ? `justify-center px-9 py-3 font-medium text-white bg-red-500 rounded-[132.798px] max-md:px-5`
                        : `justify-center px-9 py-3 max-md:px-5 text-neutral-400`
                    }>
                    Establishment
                  </div>
                </div>

                <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7 mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                  <img
                    loading="lazy"
                    src="/images/person.png"
                    className="my-auto aspect-[1.03] w-[25px]"
                    alt="person"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-black max-md:max-w-full">
                      Enter Email ID or Phone Number
                    </div>
                    <div className="mt-1 text-zinc-500 max-md:max-w-full">
                      <input
                        className="w-full border-none outline-none"
                        type="text"
                        name="email"
                        value={user.email}
                        onChange={handleInputChange}
                        placeholder="Your email address or phone number"
                      />
                    </div>
                  </div>
                </div>
                {/* Password input */}
                <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7 mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                  <img
                    loading="lazy"
                    src="/images/lock.png"
                    className="self-stretch my-auto aspect-[1.03] w-[25px]"
                    alt="lock"
                  />
                  <div className="flex flex-col self-stretch flex-1 px-0 max-md:max-w-full">
                    <div className="text-black max-md:max-w-full">Password</div>

                    <div className="mt-1 text-zinc-500 max-md:max-w-full">
                      <input
                        className="w-full border-none outline-none"
                        type={passwordVisible ? "text" : "password"} // Conditionally set input type based on password visibility
                        name="password"
                        value={user.password}
                        onChange={handleInputChange}
                        placeholder="Enter your password"
                      />
                    </div>
                  </div>
                  {passwordVisible ? (
                    <VisibilityOffIcon
                      sx={{ color: "gray", cursor: "pointer" }}
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <VisibilityIcon
                      sx={{ color: "gray", cursor: "pointer" }}
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </div>
                {/* Sign In button */}
                {error && (
                  <Alert
                    severity="error"
                    className="justify-center p-3 w-[85%] mt-4 text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3">
                    {error}
                  </Alert>
                )}
                <Button
                  loading={loading}
                  type="text"
                  style={{ height: "auto" }}
                  onClick={loginFunc}
                  className="justify-center p-3 w-[85%] mt-4 text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3">
                  Sign In
                </Button>
                {/* Forgot password link */}
                <button
                  onClick={goToForgotPass}
                  className="mt-2 text-lg text-center text-red-500">
                  Forgot Your Password?
                </button>

                {/* Social login buttons */}

                <div className="flex gap-2.5 items-center px-5 text-sm font-medium text-zinc-600 my-5">
                  <img
                    loading="lazy"
                    src="/images/rectangle1.png"
                    className="self-stretch my-auto max-w-full aspect-[100] fill-[linear-gradient(270deg,rgba(217,217,217,0.75)_0%,rgba(217,217,217,0.00)_94.39%)] w-[126px]"
                  />
                  <div className="self-stretch flex-auto">Or continue with</div>
                  <img
                    loading="lazy"
                    src="/images/rectangle2.png"
                    className="self-stretch my-auto max-w-full aspect-[100] fill-[linear-gradient(90deg,rgba(217,217,217,0.75)_0%,rgba(217,217,217,0.00)_105.61%)] w-[126px]"
                  />
                </div>
                <div className="flex gap-2.5 ">
                  <div
                    onClick={Glogin}
                    className="flex flex-1 justify-center items-center px-10 py-5 cursor-pointer bg-white shadow rounded-[120.9px]">
                    <img
                      loading="lazy"
                      src="/images/google.png"
                      className="w-5 aspect-[0.96]"
                    />
                  </div>

                  <AppleLogin
                    clientId={config.appleClientId}
                    redirectURI={config.appleRedirectUri}
                    callback={appleCallback}
                    usePopup={true}
                    scope="email name"
                    responseMode="query"
                    render={(
                      renderProps //Custom Apple Sign in Button
                    ) => (
                      <div
                        onClick={renderProps.onClick}
                        className="flex flex-1 justify-center items-center px-10 py-2 cursor-pointer bg-white shadow rounded-[120.9px]">
                        <img
                          loading="lazy"
                          src="/images/apple-black.png"
                          className="w-6 aspect-[0.83]"
                        />
                      </div>
                    )}
                  />
                </div>
                {/* Sign Up link */}
                <div className="my-4 text-center text-red-500 text-small">
                  <span className="text-zinc-600">New to Barooom? </span>
                  <Link to="/signup">Sign Up</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NotAuthenticatedLayout>
    </>
  );
}
