import { useSelector } from "react-redux";
import { getNearbyBaretenders, sendFriendRequest } from "../../../axios/axios";
import { useEffect, useState } from "react";
import config from "../../../config/configuration";
import { Button, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { formatNumber } from "../../../utils/helper";

export default function NearbyBart({ setParChange, parchange }) {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(true);
  // const [search, setSearch] = useState("");

  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    item_per_page: 12,
    total_page: 1,
    history: [],
  });

  const sendFriendReq = (to) => {
    sendFriendRequest({ token: user?.token, to })
      .then((result) => {
        toast.success(result?.message);
        setParChange(!parchange);
        console.log(result.data);
      })
      .catch((err) => console.log(err));
  };

  const nearbybardata = () => {
    getNearbyBaretenders({
      token: user?.token,
      search: "",
      page: data.page,
    })
      .then((result) => {
        let res = result.data;
        let x = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          total_page: res.total_page,
          item_per_page: res.item_per_page,
          history: [...res.history],
        };

        setData(x);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const decreasePage = () => {
    let x = { ...data };
    x = { ...x, page: x.page - 1 };
    setData(x);
  };
  const increasePage = () => {
    let x = { ...data };
    x = { ...x, page: x.page + 1 };
    setData(x);
  };

  useEffect(() => {
    nearbybardata();
  }, []);

  useEffect(() => {
    nearbybardata();
  }, [data.page]);

  return loading ? (
    <div className="flex items-center justify-center w-full h-[11.6rem]">
      <CircularProgress />{" "}
      <p className="pl-2 text-xl font-poppins">Loading...</p>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full ">
      <div>
        {data.history.map((item, index) => {
          return (
            <div
              className="z-10 flex flex-col pt-2 pb-2 mt-1 bg-white border-b border-solid rounded-md border-neutral-200 max-md:px-5 max-md:max-w-full"
              key={`NearByBar_${index}`}
            >
              <div className="flex justify-between w-full gap-5 ">
                <div className="flex items-center gap-5 max-md:flex-wrap max-md:max-w-full">
                  <div className="flex self-stretch justify-between gap-5 text-xl text-black">
                    <img
                      loading="lazy"
                      src={
                        `${config.cdnFront}/${item.friendsDetial[0].bartenderDetails[0].profileImg}` ||
                        ""
                      }
                      className="shrink-0 rounded-full border-4 border-white border-solid shadow-lg aspect-square h-[60px] w-[60px]"
                    />
                    <div
                      className={`my-auto text-[17px] flex-auto self-stretch} `}
                    >
                      {item.friendsDetial[0].name}
                    </div>
                  </div>
                  <div className="self-stretch flex-auto my-auto text-base text-zinc-500">
                    {item.mutualFriends.MutualFriend} mutual connections
                  </div>
                  <div className="flex items-start self-end gap-1 ">
                    <img
                      loading="lazy"
                      src="/images/Comminity/location.svg"
                      className="shrink-0 aspect-square mt-[6px] w-[22px]"
                    />
                    <div className="flex flex-col mt-1.5">
                      <div className="text-base text-black">
                        {
                          item.friendsDetial[0].bartenderDetails[0].location
                            .city
                        }
                      </div>
                      <div className="text-sm text-zinc-400">
                        {formatNumber(item.distance)} mi
                      </div>
                    </div>
                  </div>
                  <div className="flex self-stretch gap-2 my-auto text-xl font-medium text-black whitespace-nowrap">
                    <img
                      loading="lazy"
                      src="/images/Group.svg"
                      className="shrink-0 aspect-[0.96] w-[23px]"
                    />
                    <div className="my-auto text-base">
                      {item.mutualFriends.TotalFriend}
                    </div>
                  </div>
                  <div className="flex gap-1.5 self-stretch my-auto text-xl font-medium text-black whitespace-nowrap">
                    <img
                      loading="lazy"
                      src="/images/Building.svg"
                      className="w-6 shrink-0 aspect-square"
                    />
                    <div className="my-auto text-base">
                      {item.friendsDetial[0].totalJobs}
                    </div>
                  </div>
                  <div className="flex self-stretch gap-2 my-auto text-xl font-medium text-black whitespace-nowrap">
                    <img
                      loading="lazy"
                      src="/images/StartRating.svg"
                      className="w-6 shrink-0 aspect-square"
                    />
                    <div className="my-auto text-base">
                      {item.friendsDetial[0].totalReviews}
                    </div>
                  </div>
                </div>
                <div
                  className="flex gap-3.5 my-auto text-sm text-center whitespace-nowrap cursor-pointer"
                  onClick={() => sendFriendReq(item.friendsDetial[0]._id)}
                >
                  <div className="justify-center px-5 py-3 text-white bg-emerald-500 rounded-[92.207px]">
                    Add Friend
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {data.total_page > 1 ? (
        <div
          style={{
            width: "auto",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "25px 0 0 0",
            padding: "0 20px",
          }}
        >
          <button type="text" onClick={decreasePage}>
            Previous
          </button>

          {Array.from({ length: data.total_page }).map((elem, ind) => (
            <button
              key={`Pagi${ind}`}
              style={{
                height: "20px",
                width: "20px",
                padding: "0",
                fontSize: "12px",
                margin: "0 3px",
                background:
                  data.page === ind + 1 ? "rgb(255, 51, 51)" : "white",
                color: data.page === ind + 1 ? "white" : "black",
              }}
            >
              {ind + 1}
            </button>
          ))}

          <button type="text" onClick={() => increasePage()}>
            Next
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
