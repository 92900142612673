import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const Onboarding = () => {
    const user = useSelector((state) => state.user.user.token)
    const [loading, setLoading] = useState(false);

    const handleOnboard = async () => {
        setLoading(true);
        try {
            const response = await fetch('http://localhost:11015/api/v1/create-account-link', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user}`

                },
            });

            const { url } = await response.json();

            if (url) {
                // Set the onboarding URL to render inside the iframe
                window.location.href = url;
            } else {
                console.error('Error: Account link URL not found.');
            }
        } catch (error) {
            console.error('Error creating account link:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>

            <button onClick={handleOnboard} disabled={loading}>
                {loading ? 'Loading...' : 'Start Setup'}
            </button>

        </div>
    );
};

export default Onboarding;
