import React, { useEffect, useState } from "react";
import styles from "../profile.module.css";
import config from "../../../config/configuration";
import {
  fetchReviewOnBartender,
  fetchSetAvailability,
  fetchUserData,
  getPastGigsforBart,
  PatchBarProfileImg,
} from "../../../axios/axios";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DashHeader from "../../dashboard/DashHeader";
import configuration from "../../../config/configuration";
import ProfileReviewCards from "../../Reviews/Bartendar/ProfileReviewCards";
import { updateUser } from "../../../redux/userSlice";
import BartenderWrapperDash from "../../dashboard/WrapperDash";
import EditProfile from "./EditProfile";
import { displayFileName } from "../../../utils/helper";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function MainProfile() {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const [userdata, setUserData] = useState();
  const [gigsattended, setGigsAttended] = useState([]);
  const [availability, setAvailability] = useState();
  const [availloading, setAvailLoading] = useState(true);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const showEditModal = () => {
    if (location.search && location.search.split("=")[0]) {
      setShowEditProfile(true);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      PatchBarProfileImg({ token: user?.token, profileImg: e.target.files[0] })
        .then((result) => {
          dispatch(updateUser({ pic: result.data.data }));
        })
        .catch((err) => {
          toast.error(err?.message || "");
          console.error(err);
        });
    }
  };

  const startOfWeek = dayjs().startOf("week");
  const month = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const [week, setWeek] = useState([
    {
      day: "Monday",
    },
    {
      day: "Tuesday",
    },
    {
      day: "Wednesday",
    },
    {
      day: "Thursday",
    },
    {
      day: "Friday",
    },
    {
      day: "Saturday",
    },
    {
      day: "Sunday",
    },
  ]);

  const [reviews, setReviews] = useState({
    prev: false,
    next: false,
    search: "",
    pending: "",
    paid: "",
    filter: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  const getUserData = () => {
    fetchUserData({ token: user?.token })
      .then((result) => {
        setUserData(result.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getGigsHosted = () => {
    getPastGigsforBart({
      token: user?.token,
      filter1: "",
      filter2: "",
      page: "",
      search: "",
    })
      .then((result) => {
        setGigsAttended(result?.data?.data?.history);
      })
      .catch((err) => console.log(err));
  };

  const getSetAvailability = () => {
    fetchSetAvailability({
      token: user?.token,
    })
      .then((result) => {
        setAvailability(result.data.data);
        setAvailLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReviews = () => {
    fetchReviewOnBartender({
      token: user?.token,
      filter: "",
    })
      .then((result) => {
        let res = result.data.data;
        let x = { ...reviews };

        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };

        setReviews(x);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserData();
    getGigsHosted();
    getReviews();
    getSetAvailability();
  }, []);

  useEffect(() => {
    showEditModal();
  }, []);

  return (
    <BartenderWrapperDash>
      <div>
        <DashHeader
          leftContent={
            <>
              <div className="flex gap-3 pt-3 my-auto uppercase grow">
                <span className=" text-neutral-700">your</span>{" "}
                <span className="text-yellow-400">Profile</span>
                <img
                  src="/images/Edit_Icon.svg"
                  className="mx-5 cursor-pointer aspect-square"
                  onClick={() => setShowEditProfile(true)}
                />
              </div>
            </>
          }
        />
      </div>
      <div className="flex justify-between mt-8">
        <div className="w-[70%]">
          <div
            style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
            className="relative px-5 pb-5 bg-white rounded-3xl max-md:px-5 max-md:max-w-full"
          >
            <div className="flex items-center max-xl:flex-col max-md:gap-0">
              <div className="size-[150px] relative rounded-[50%]">
                {selectedImage != null ? (
                  <img
                    loading="lazy"
                    src={selectedImage}
                    className="size-full rounded-[50%]"
                    alt=""
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={
                      userdata?.profileImg
                        ? `${config.cdnFront}/${userdata?.profileImg}`
                        : "/images/Dummy_User.jpg"
                    }
                    alt="User"
                    className="size-full rounded-[50%] mt-1"
                  />
                )}

                <div className="absolute bottom-0 right-0">
                  <img loading="lazy" src="/images/Camera_Icon.svg" />
                </div>
                <input
                  type="file"
                  className="absolute top-0 w-full h-full opacity-0"
                  // value={selectedImage}
                  onChange={(e) => handleFileChange(e)}
                />
              </div>
              <div className="flex flex-col ml-3 w-[83%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col mt-0 grow max-md:mt-2 max-md:max-w-full">
                  <div className="flex items-start justify-between w-full gap-5 max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col self-end mt-4 max-xl:mx-auto max-md:mt-10">
                      <h1 className="text-xl font-semibold text-amber-300">
                        {userdata?.uid?.name}
                      </h1>
                      <p className="text-sm text-gray-600">
                        {userdata?.uid.email}
                      </p>
                      <p className="text-sm tracking-wide max-xl:mx-auto text-slate-950">
                        {userdata?.designation?.map((elem) => `${elem} `)}
                      </p>
                      <div className="h-px mt-3 border border-solid shrink-0 bg-neutral-400 border-neutral-400" />
                    </div>
                    <div className="flex absolute top-[-20px] right-[20px] gap-5 justify-between items-start self-start text-center">
                      <div className="flex flex-col whitespace-nowrap bg-white rounded-full border border-solid border-zinc-300 size-[80px]">
                        <img
                          loading="lazy"
                          src="/images/completeProfile/Vector.png"
                          className="z-10 self-center mt-0 aspect-[1.10] absolute top-[-8px]  fill-yellow-400 w-[28px]"
                        />
                        <p className="mt-5 text-sm font-medium text-black">
                          {parseFloat(userdata?.UserData?.avgrating).toFixed(1)}
                        </p>
                        <p className="text-[11px]">Rating</p>
                      </div>
                      <div className="flex rounded-[50%] size-[80px] justify-center items-center flex-col border-4 border-white bg-slate-100 p-2">
                        <p className="text-2xl font-medium text-red-500">
                          {userdata?.UserData?.totalJobs}
                        </p>
                        <p className=" text-[11px] text-neutral-400">Gigs</p>
                      </div>
                      <div className="flex rounded-[50%] size-[80px] justify-center items-center flex-col border-4 border-white bg-[#F4F5FA] p-2">
                        <div className="text-2xl font-medium text-red-500">
                          {userdata?.yearsOfExperience || 0}
                          <span className="text-sm"> Yrs</span>
                        </div>
                        <p className="text-[11px] text-neutral-400">
                          Experience
                        </p>
                      </div>
                    </div>
                  </div>
                  <p
                    className={`mt-3 text-sm text-black  max-md:max-w-full} ${styles.header}`}
                  >
                    About me
                  </p>
                  <p className="mt-1 text-sm leading-5 text-neutral-700 max-md:max-w-full">
                    {userdata?.bio}
                  </p>
                  <div className="flex gap-1.5 self-start mt-3 text-xs text-black">
                    {userdata?.skills.map((d) => (
                      <section className="justify-center px-3.5 py-2.5 bg-neutral-100 rounded-[100px]">
                        {d}
                      </section>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
            className="py-8 pl-10 pr-20 bg-white mt-7 rounded-3xl max-md:px-5 max-md:max-w-full"
          >
            <div className="flex gap-10 text-sm max-xl:flex-col">
              <div>
                <div className={`text-sm font-bold`}>Website Link</div>
                <div>{userdata?.portfoliolink || "-"}</div>
              </div>
              <div>
                <div className={`text-sm font-bold`}>LinkedIn Link</div>
                <div>{userdata?.linkedinlink || "-"}</div>
              </div>
              <div>
                <div className={`text-sm font-bold`}>Instagram Link</div>
                <div>{userdata?.instagramlink || "-"}</div>
              </div>
            </div>
            <div className={`text-base mt-7 font-bold`}>Documents</div>
            {/* <img
              loading="lazy"
              src="/images/completeProfile/pdf.png"
              className="w-5 aspect-[0.95] fill-neutral-400"
            /> */}
            <div className="flex flex-wrap gap-10 mt-3 max-md:gap-3 max-md:flex-col">
              {userdata?.resume?.resumenName ? (
                <div className="flex flex-col items-center pb-3 mt-3 max-w-[150px] rounded-xl bg-zinc-100">
                  <div className="flex items-center self-stretch justify-center px-5 py-6 bg-white border-4 border-solid rounded-xl border-zinc-100 max-md:px-5">
                    <img
                      loading="lazy"
                      src="/images/completeProfile/pdf.png"
                      className="w-5 aspect-[0.95] fill-neutral-400"
                    />
                  </div>
                  <div className="px-8 mt-2 text-xs leading-4 text-black text-nowrap">
                    {userdata?.resume?.resumenName
                      ? displayFileName({
                          fileName: userdata?.resume.resumenName,
                        })
                      : ""}
                  </div>
                  <div className="px-8 mt-3 text-xs leading-4 text-stone-500">
                    {userdata?.resume?.size} KB
                  </div>
                </div>
              ) : (
                <div>No Documents Uploaded</div>
              )}
              {userdata?.certifications.length > 1
                ? userdata?.certifications?.map((elem) => (
                    <div className="flex flex-col items-center pb-3 mt-3 max-w-[150px] rounded-xl bg-zinc-100 overflow-hidden">
                      {elem.fileType != "pdf" ? (
                        <div className="flex items-center self-stretch justify-center px-5 py-6 bg-white border-4 border-solid rounded-xl border-zinc-100 max-md:px-5">
                          <img
                            loading="lazy"
                            src="/images/completeProfile/pdf.png"
                            className="w-5 aspect-[0.95] fill-neutral-400"
                          />
                        </div>
                      ) : (
                        <div className="max-h-[50%] max-w-[150px] rounded-xl overflow-hidden m-1">
                          <img
                            loading="lazy"
                            src={
                              `${configuration.cdnFront}/certificates_1_8B0G2R1C3M6C8RP.jpeg` ||
                              ""
                            }
                          />
                        </div>
                      )}
                      <div className="px-8 mt-2 text-xs leading-4 text-black text-nowrap">
                        {displayFileName({ fileName: elem?.certificateName })}
                      </div>
                      <div className="px-8 mt-3 text-xs leading-4 text-stone-500">
                        {parseFloat(elem?.size / (1024 * 1024)).toFixed(2)} MB
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
          <div
            style={{ boxShadow: "0px 4px 44px 0px rgba(0, 0, 0, 0.06)" }}
            className="py-8 bg-white pl-9 mt-7 rounded-3xl max-md:px-5 max-md:max-w-full"
          >
            <div className={`text-base text-black mb-5} ${styles.header}`}>
              Reviews
            </div>
            <div className="grid max-w-full grid-cols-2 gap-5 mx-auto mt-8 xl:grid-cols-3">
              {reviews.history.length > 0 ? (
                reviews.history.map((item, index) => {
                  return <ProfileReviewCards item={item} key={index} />;
                })
              ) : (
                <p>No Reviews</p>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-2 px-8 pt-5 pb-20 max-w-[25%] min-w-[25%] bg-white rounded-3xl shadow-2xl max-xl:px-5 max-xl:mx-3 max-xl:mt-7 max-xl:max-w-full">
          <div className="flex items-start w-full gap-8 px-px max-md:flex-wrap max-md:max-w-full">
            <div
              className={`flex-auto text-xl text-slate-900} ${styles.header}`}
            >
              Your Availability
            </div>
          </div>
          <div className="mt-8">
            {week.map((elem, ind) => (
              <div className="flex items-center gap-8 my-2">
                <div className="min-w-[76px] bg-slate-100  border-t-[4.69px] border-amber-300 rounded-3xl min-h-[76px] flex flex-col justify-center">
                  <div className="text-center text-neutral-400">
                    {month[startOfWeek.month()]}
                  </div>
                  <div className="text-center text-black">
                    {startOfWeek.add(ind, "day").get("date")}
                  </div>
                </div>
                <div className="">
                  <div className="text-neutral-400">{elem.day}</div>
                  {!availloading ? (
                    <div>
                      {availability?.[week[ind]?.day.toLowerCase().slice(0, 3)]
                        .length < 1 ? (
                        <div>
                          <p className="">Unavailable</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {availability?.[
                        week[ind]?.day.toLowerCase().slice(0, 3)
                      ]?.map((el, ind) => (
                        <p key={`Slot_${ind}`}>
                          {dayjs(el.starttime, "HH:mm").format("hh:mm A")} -{" "}
                          {dayjs(el.endtime, "HH:mm").format("hh:mm A")}
                        </p>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showEditProfile ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto">
              <div className="relative flex flex-col w-full bg-white outline-none rounded-xl focus:outline-none">
                <button
                  className="self-end pt-3 pr-4 text-2xl"
                  onClick={() => setShowEditProfile(false)}
                >
                  &#10005;
                </button>
                <EditProfile
                  userdata={userdata}
                  selectedImage={selectedImage}
                  handleFileChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
    </BartenderWrapperDash>
  );
}

export default MainProfile;
