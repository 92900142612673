import JobCards from "../../../jobCards/JobCards";

function PastGigsEstablishment({ data, dayjs }) {
  return (
    <div className="h-[50rem] lg:h-full">
      <div className="flex flex-wrap w-full h-full gap-6 px-2 pt-6 overflow-y-scroll no-scrollbar">
        {data.map((elem, index) => {
          return (
            <JobCards
              elem={elem}
              index={index}
              Jtype={3}
              key={`job_${elem?._id}_${index}_past`}
            />
          );
        })}
        {data?.length < 1 && (
          <div className="flex flex-col items-center justify-center w-full">
            <img src="/images/No_Data.svg" sizes="20" alt="" />
            <p className="text-xl font-medium font-Roboto translate-y-[-1rem]">
              No Past Gigs{" "}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
export default PastGigsEstablishment;
