import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import { Badge, Calendar } from "antd";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../celender.module.css";
import { motion, AnimatePresence } from "framer-motion";
import { TbCalendarTime } from "react-icons/tb";

import {
  fetchDateScheduleEstab,
  fetchMonthlyScheduleEstab,
  fetchSetAvailability,
} from "../../../axios/axios";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import BartenderAvailToday from "../../dashboard/BartenderAvailToday";
import configuration from "../../../config/configuration";
import useSwipeHandler from "../../useSwipeHandler";
import useSwipeHandlerNoLimit from "../../useSwipeHandlerNoLimit";

function CelenderEst() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [panel, setPanel] = useState(dayjs().format("YYYY-MM-DD"));
  const [showdayschedule, setShowDaySchedule] = useState(false);
  const [activday, setActiveDay] = useState(dayjs().format("YYYY-MM-DD"));
  const [activejob, setActiveJob] = useState();

  const [selectedDates, setSelectedDates] = useState({});

  const getDaySchedule = ({ daydate }) => {
    let dstr = dayjs(daydate).format("DD-MM-YYYY");
    let strt = dayjs(`${dstr} 00:00`,"DD-MM-YYYY HH:mm").toDate();
    let end = dayjs(`${dstr} 23:59`,"DD-MM-YYYY HH:mm").toDate();

    fetchDateScheduleEstab({
      startdate: strt,
      enddate: end,
      token: user?.token,
      // timeZone: dayjs.tz.guess(),
    })
      .then((result) => {
        setActiveDay(dayjs(daydate).format("YYYY-MM-DD"));
        setActiveJob(result.data.data);
        setShowDaySchedule(true);
      })
      .catch((err) => {
        console.log("Monthly err", err);
      });
  };

  // function getDates(startDate, stopDate) {
  //   var dateArray = [];
  //   var currentDate = dayjs(startDate).tz(dayjs.tz.guess(), true);
  //   var stopDate = dayjs(stopDate).tz(dayjs.tz.guess(), true);

  //   while (currentDate <= stopDate) {
  //     dateArray.push(dayjs(currentDate).format("DD"));
  //     currentDate = dayjs(currentDate).add(1, "days");
  //   }
  //   dateArray.push(dayjs(stopDate).format("DD"));
  //   return dateArray;
  // }

  const onPanelChange = (value, mode) => {
    setPanel(value.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    let year = panel.split("-")[0];
    let month = panel.split("-")[1];

    fetchMonthlyScheduleEstab({ year, month, token: user.token })
      .then((result) => {
        let dArr = [];

        result.data.data.forEach((element) => {
          let a = parseInt(
            dayjs(element.startdate).format("DD-MM-YYYY").split("-")[0]
          );
          let b = parseInt(
            dayjs(element.enddate).format("DD-MM-YYYY").split("-")[0]
          );
          let diff = Math.abs(a - b);

          dArr.push(a);
          Array.from({ length: diff }).forEach(() => {
            dArr.push(++a);
          });
        });

        let newDarr = [...new Set(dArr)];
        let ob = {};

        newDarr = newDarr.forEach((elem) => (ob[elem] = 1));

        setSelectedDates(ob);
      })
      .catch((err) => {
        console.log("Monthly err", err);
      });
  }, [panel]);

  const dateCellRender = (value) => {
    let keys = Object.keys(selectedDates);
    const listData = [];
    if (keys.indexOf(`${value.date()}`) !== -1) {
      listData.push(
        ...Array.from({ length: selectedDates[`${value.date()}`] })
      );
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
        }}>
        {listData.map((item, ind) => (
          <Badge key={`${ind}_SOME`} color="red" size="default" />
        ))}
      </div>
    );
  };
  const renderHeader = () => {
    return (
      <div className="flex items-center gap-2">
        <img
          src="/images/LessTan.svg"
          className="w-10 h-auto transition-transform cursor-pointer aspect-square hover:scale-110"
          onClick={() => onPanelChange(dayjs(panel).subtract(1, "month"))}
        />
        <p className="text-[#7D8DA6] font-poppins text-xl leading-6 font-medium">
          {dayjs(panel).format("MMMM")}
        </p>
        <p className="text-[#7D8DA6] font-poppins text-xl leading-6 font-medium">
          {dayjs(panel).format("YYYY")}
        </p>
        <img
          src="/images/GreaterThan.svg"
          className="w-10 h-auto transition-transform cursor-pointer aspect-square hover:scale-110"
          onClick={() => onPanelChange(dayjs(panel).add(1, "month"))}
        />
      </div>
    );
  };

  const swipeHandlers = useSwipeHandlerNoLimit({
    onPanelChange: onPanelChange,
    panel: panel,
  });

  const cellRender = (current, info) => {
    if (info.type === "date") {
      if (!current.isSame(panel, "month")) {
        return <div style={{ visibility: "hidden" }}></div>; // Hide dates outside the current month
      }
      return dateCellRender(current);
    }

    return <></>;
  };
  return (
    <div>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className="text-neutral-700">calendar</span>
            </div>
          </>
        }
      />
      <div className="flex mt-5">
        <div
          className="flex flex-col w-[350px] mr-5 grow mt-2.5 max-md:mt-8 max-md:max-w-full"
          {...swipeHandlers}>
          <Calendar
            headerRender={renderHeader}
            onPanelChange={onPanelChange}
            onSelect={(dt) => {
              if (dt.isSame(panel, "month")) {
                getDaySchedule({ daydate: dt });
              }
            }}
            cellRender={cellRender}
            value={dayjs(panel)}
            // validRange={[startOfMonth, endOfMonth]}
          />
        </div>

        <div className="relative w-[30%]">
          <AnimatePresence>
            {showdayschedule ? (
              <motion.div
                key="showdayschedule"
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="absolute flex flex-col flex-1 w-full max-h-full px-5 pb-10 bg-white shadow-2xl grow pt-7 rounded-3xl max-md:mt-6 max-md:max-w-full">
                <p
                  className="absolute cursor-pointer top-2 right-5"
                  onClick={() => setShowDaySchedule(false)}>
                  <CancelIcon
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      transform: "translate(60%,-60%)",
                      cursor: "pointer",
                      color: "#FF3333",
                      background: "white",
                      fontSize: "30px",
                      borderRadius: "50%",
                    }}
                  />
                </p>
                <div className="flex max-md:flex-wrap max-md:max-w-full">
                  <div className="flex justify-between flex-auto my-auto text-[16px] text-slate-900">
                    {activday}
                    {activejob?.length > 0 ? (
                      <p>Total Schedule: {activejob?.length}</p>
                    ) : (
                      <p>No Schedule</p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2 overflow-y-scroll mt-9 no-scrollbar">
                  {activejob?.map((elem) => (
                    <div key={elem._id} className="flex gap-2 p-2">
                      <div className="w-fit">
                        <p className="pt-2 text-xs lg:whitespace-nowrap">
                          {dayjs(elem.startTime, "HH:mm").format("hh:mm A")}
                        </p>
                      </div>
                      <div
                        className="bg-[#7575f8] text-white w-full  flex flex-col gap-1 px-4 py-2 rounded-xl text-xs cursor-pointer"
                        onClick={() => navigate(`/gig/${elem._id}`)}>
                        <p className="text-[1rem]">
                          {elem?.establishment?.[0]?.establishmentName}
                        </p>
                        <p className="text-[0.8rem]">{elem?.jobTitle}</p>
                        <div className="flex items-center gap-2 ">
                          <TbCalendarTime size={12} />
                          Shift Date:{" "}
                          {dayjs(elem.startdate).format("MM/DD/YYYY")} {"|"}{" "}
                          {dayjs(elem.enddate).format("MM/DD/YYYY")}
                        </div>
                        <div className="flex items-center gap-2 ">
                          <TbCalendarTime size={12} />
                          Shift Time:{" "}
                          {dayjs(elem.startTime, "HH:mm").format(
                            "hh:mm A"
                          )}{" "}
                          {"|"} {dayjs(elem.endTime, "HH:mm").format("hh:mm A")}
                        </div>
                        <div className="flex p-2">
                          {elem?.assigned?.slice(0, 3).map((users, ind) => (
                            <img
                              key={users._id}
                              loading="lazy"
                              src={`${configuration.cdnFront}/${users?.pic}`}
                              className={`size-8 rounded-full`}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="bartenderavail"
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.3 }}
                className="absolute w-full">
                <BartenderAvailToday />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default CelenderEst;
