import React from 'react'

function SEOLandingSection3() {
    return (
        <div className='h-[80vh] relative flex items-center flex-col w-[100vw]'>
            <h1 className='text-4xl font-normal font-Righteous'>How Barooom Works in <span className='text-4xl font-Righteous text-error'>Charlotte</span>?</h1>
            <p className='pt-3 font-poppins text-gray-1 text-wrap max-w-[55%] text-center'>Barooom is designed to streamline the hiring process for bars and hospitality establishments throughout Charlotte. Whether you’re in Raleigh, Charlotte, Asheville, or Wilmington, our platform connects you directly with talented bartenders and staff who are ready to provide outstanding service.</p>
            <section className='relative flex flex-col items-center justify-center w-full px-5 mt-5'>
                <div className='flex w-full px-20'>
                    <div className='flex flex-col items-center justify-center max-w-72'>
                        <img src="/images/01_Create.svg" alt="create logo" className='w-auto h-16' />
                        <h3 className='text-xl font-normal font-Righteous'>01 Create Your Account</h3>
                        <p className='text-sm text-center font-poppins text-gray-1 text-wrap'>Sign up on our platform by providing some basic information about your establishment.</p>
                    </div>
                    <img src="/images/02_Arrow.svg" alt="create logo" className='ml-48' />
                    <div className='flex flex-col items-center justify-center max-w-72'>
                        <img src="/images/03_Review.svg" alt="Review logo" className='w-auto h-16' />
                        <h3 className='text-xl font-normal font-Righteous'>03 Review Applications</h3>
                        <p className='text-sm text-center font-poppins text-gray-1 text-wrap'>Browse through applications from qualified bartenders and hospitality professionals who are eager to work with you.</p>
                    </div>
                    <img src="/images/03_Arrow.svg" alt="create logo" />

                </div>
                <div className='flex w-[85%] ml-auto -translate-y-5'>
                    <img src="/images/01_Arrow.svg" alt="create logo" className='' />
                    <div className='flex flex-col items-center justify-center ml-20 max-w-72'>
                        <img src="/images/02_Post.svg" alt="post logo" className='w-auto h-16' />
                        <h3 className='text-xl font-normal font-Righteous'>02 Post Your Job Listing</h3>
                        <p className='text-sm text-center font-poppins text-gray-1 text-wrap'>Describe your specific needs, including the type of event, required skills, and number of staff needed.</p>
                    </div>
                    <div className='flex flex-col items-center justify-center ml-80 max-w-72'>
                        <img src="/images/04_Connect.svg" alt="Connect logo" className='w-auto h-16' />
                        <h3 className='text-xl font-normal font-Righteous'>05 Connect and Hire</h3>
                        <p className='text-sm text-center font-poppins text-gray-1 text-wrap'>Communicate directly with candidates to discuss your expectations and finalize your bookings.</p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SEOLandingSection3