import React, { useState } from 'react'
import { createEarlyRegiEstab } from '../../axios/axios';
import { toast } from 'react-toastify';

function SEOLandingSection7() {
    const [data, setdata] = useState({
        fullName: "",
        email: "",
        phoneNo: "",
        businessName: "",
        location: "",
        comments: "",
    });

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setdata({ ...data, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateFormData(data)) {
            createEarlyRegiEstab({ data })
                .then((res) => {
                    toast.success(res.data.message);
                    setdata({
                        fullName: "",
                        email: "",
                        businessName: "",
                        phoneNo: "",
                        location: "",
                        comments: "",
                    });
                })
                .catch((err) => toast.error(err?.message));
        }
    };

    const validateFormData = (data) => {
        let isValid = true;

        if (!data.fullName) {
            toast.error("Full Name is required");
            isValid = false;
            return;
        }

        if (!data.email) {
            toast.error("Email is required");
            isValid = false;
            return;
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            toast.error("Email is invalid");
            isValid = false;
            return;
        }
        if (!data.phoneNo) {
            toast.error("Phone Number is required");
            isValid = false;
            return;
        } else if (!/^\d+$/.test(data.phoneNo)) {
            toast.error("Phone Number is invalid");
            isValid = false;
            return;
        } else if (data.phoneNo.length < 10) {
            toast.error("Phone Number must be at least 10 digits long");
            isValid = false;
            return;
        }

        if (!data.businessName) {
            toast.error("Business Name is required");
            isValid = false;
            return;
        }

        if (!data.location) {
            toast.error("Location is required");
            isValid = false;
            return;
        }

        // if (!data.comments) {
        //     toast.error("Type in your comments");
        //     isValid = false;
        //     return;
        // }

        return isValid;
    };
    return (
        <div className='min-h-[100vh] mt-5'>
            <div className='bg-no-repeat bg-cover bg-seo-landing-back-card-02 h-[65vh] pl-10 flex  flex-col justify-center'>
                <h1 className='text-4xl font-normal leading-loose text-white font-Righteous'>Local <span className='text-4xl font-Righteous text-error'>Expertise</span></h1>
                <p className='text-lg max-w-[40%] text-wrap font-normal text-white font-poppins'>Understanding the local culture and preferences is key to providing great bartending services.</p>
                <p className='text-lg font-normal text-white font-poppins max-w-[50%] text-wrap mt-2'>Our bartenders are familiar with Charlotte’s beverage trends, local ingredients, and regional favorites. They can craft drinks that resonate with your guests, adding a personal touch to your event.</p>
            </div>
            <div className='flex w-[100vw] h-[90vh] px-32 gap-5'>
                <div className='w-1/2 h-full p-4 -translate-y-28 rounded-xl'>
                    <div className='custom-glass-form'>
                        <form className='px-5 py-8 bg-white rounded-xl'>
                            <h1 className='text-2xl font-normal text-center font-Righteous'>Sign Up Now for Early Access!</h1>
                            <div className='flex items-center justify-center w-full'>
                                <div className='w-32 h-[0.2rem] my-4 rounded-full bg-estab-back' />

                            </div>
                            <div className='flex flex-col w-full h-full gap-5'>
                                <label>
                                    <p className='font-Righteous'>Full name <span className='text-error font-Righteous'>*</span></p>
                                    <input type="text" className='w-full h-10 text-base rounded-full form '
                                        style={{ border: '1px solid #CBD6E2' }}
                                        value={data.fullName}
                                        name='fullName'
                                        onChange={(e) => handleChange(e)} />
                                </label>
                                <label>
                                    <p className='font-Righteous'>Email Address <span className='text-error font-Righteous'>*</span></p>
                                    <input type="text" className='w-full h-10 text-base rounded-full form '
                                        style={{ border: '1px solid #CBD6E2' }}
                                        value={data.email}
                                        name='email'
                                        onChange={(e) => handleChange(e)} />
                                </label>
                                <label>
                                    <p className='font-Righteous'>Phone Number <span className='text-error font-Righteous'>*</span></p>
                                    <input type="text" className='w-full h-10 text-base rounded-full form '
                                        style={{ border: '1px solid #CBD6E2' }}
                                        value={data.phoneNo}
                                        name='phoneNo'
                                        onChange={(e) => handleChange(e)} />
                                </label>
                                <label>
                                    <p className='font-Righteous'>Business Name <span className='text-error font-Righteous'>*</span></p>
                                    <input type="text" className='w-full h-10 text-base rounded-full form '
                                        style={{ border: '1px solid #CBD6E2' }}
                                        value={data.businessName}
                                        name='businessName'
                                        onChange={(e) => handleChange(e)} />
                                </label>
                                <label>
                                    <p className='font-Righteous'>Location <span className='text-error font-Righteous'>*</span></p>
                                    <input type="text" className='w-full h-10 text-base rounded-full form '
                                        style={{ border: '1px solid #CBD6E2' }}
                                        value={data.location}
                                        name='location'
                                        onChange={(e) => handleChange(e)} />
                                </label>
                                <label>
                                    <p className='font-Righteous'>Other Comments </p>
                                    <input type="text" className='w-full h-10 text-base rounded-full form '
                                        style={{ border: '1px solid #CBD6E2' }}
                                        value={data.comments}
                                        name='comments'
                                        onChange={(e) => handleChange(e)} />
                                </label>

                            </div>
                            <button className='flex items-center justify-center w-full h-10 gap-2 px-2 mt-4 font-semibold text-white rounded-full bg-estab-back font-poppins' onClick={(e) => handleSubmit(e)}>Get Started <img src="/images/arrow.svg" alt="" /></button>
                        </form>

                    </div>
                </div>
                <div className='flex flex-col justify-center w-1/2'>
                    <h1 className='text-4xl font-normal leading-loose text-black font-Righteous'>Join the <span className='text-4xl font-Righteous text-error'>Barooom Community</span></h1>
                    <p className='font-normal font-poppins text-gray-1 '>Whether you’re hosting a large event, need extra help for a busy weekend, or are looking for long-term staffing solutions, Barooom offers flexible options to fit your specific needs. With a comprehensive database of skilled professionals across Charlotte, you can easily find the perfect match for your establishment.</p>
                    <p className='mt-4 font-normal font-poppins text-gray-1'>If you have any questions or need assistance, feel free to reach out to our team. We’re here to help you every step of the way. Let’s work together to create memorable experiences for your guests through exceptional bartending services.</p>
                </div>
            </div>
        </div>
    )
}

export default SEOLandingSection7