import React, { useState } from "react";
import style from "../../eventDeatail.module.css";
import { Link, useNavigate } from "react-router-dom";
import configuration from "../../../../config/configuration";
import dayjs from "dayjs";
import { handleFloat } from "../../../../utils/helper";
import { Modal } from "@mui/material";
import ReviewModal from "../../../Reviews/ReviewModal";

function Payment1({ bartenders, jobdata }) {
  const [showModal1, setShowModal1] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModal3, setShowModal3] = React.useState(false);
  const navigate = useNavigate();
  const [givenrating, setGivenRating] = useState({
    establishmentId: "",
    detail: "",
    overall: 0,
    experience: 0,
    staff: 0,
    value: 0,
  });
  const [activeEstab, setactiveEstab] = useState();
  return (
    <div className="h-full p-2">
      <div className={`text-[17px] text-black ${style.header}`}>
        Bartenders of {jobdata?.establishmentData?.[0]?.establishmentName}{" "}
        Establishment
      </div>
      <div className="p-4">
        {bartenders?.length > 0 &&
          bartenders?.map((elem, ind) => {
            return (
              <div
                key={`PastGIG_${elem._id}`}
                className="flex flex-col items-center justify-center"
              >
                <div
                  style={{
                    boxShadow: "0px 0px 21.461px 0px rgba(0, 0, 0, 0.10)",
                  }}
                  className="flex flex-col pl-2 pt-1.5 pb-2  rounded-xl border-l-4 border-red-500 border-solid "
                >
                  <div className="flex gap-2.5">
                    <img
                      loading="lazy"
                      src={
                        `${configuration.cdnFront}/${elem?.pic}` ||
                        "/images/completeProfile/user1.png"
                      }
                      className="self-start shrink-0 size-14 rounded-xl"
                    />

                    <div className="flex flex-col pr-2 grow shrink-0 basis-0 w-fit">
                      <div className="flex justify-between gap-5">
                        <div className="flex-auto text-sm font-medium tracking-tighter text-black">
                          {elem?.name}
                        </div>
                      </div>
                      <div className="text-[12px] mt-[2px] flex text-neutral-400">
                        <img
                          loading="lazy"
                          src="/images/completeProfile/clock.png"
                          className="self-start shrink-0 mt- aspect-square"
                        />
                        Shift Time:{" "}
                        {dayjs(jobdata?.startTime, "HH:mm").format("hh:mm A")} -{" "}
                        {dayjs(jobdata?.endTime, "HH:mm").format("hh:mm A")} |
                        Pay: ${handleFloat(jobdata?.payHourly)}/hr
                      </div>
                      <section className="flex-auto text-[#9D9D9D] my-auto text-[12px]">
                        <Link to={`/bartender-details/${elem?._id}`}>
                          View Profile
                        </Link>
                      </section>
                    </div>
                  </div>
                </div>
                <div
                  className="justify-center items-center cursor-pointer py-2.5 mt-2 text-sm text-center text-white capitalize bg-red-500 rounded-[121px] w-full xl:w-[75%]"
                  onClick={() =>
                    navigate(`/make-payment/${elem?._id}/${jobdata?._id}`)
                  }
                >
                  <p>make Payment</p>
                </div>
                <div className="flex self-center gap-3 mt-2 mb-5 text-sm text-center text-black capitalize max-md:flex-wrap">
                  <button
                    onClick={() => setShowModal2(true)}
                    className="justify-center self-center px-7 py-2.5 bg-white border border-amber-300 border-solid rounded-[121px] max-md:px-5"
                  >
                    Mark ‘No Show’
                  </button>
                  <button
                    onClick={() => setShowModal3(true)}
                    className="justify-center self-center px-7 py-2.5 rounded-[121px] bg-white border border-red-500 border-solid "
                  >
                    Write Review
                  </button>
                </div>
              </div>
            );
          })}
      </div>

      <>
        {showModal1 ? (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-3xl mx-auto my-6">
                {/*content*/}
                <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-3xl focus:outline-none">
                  {/*header*/}
                  <div>
                    <div className="flex flex-col px-10 pt-9 pb-6 text-center bg-white rounded-3xl max-w-[400px]">
                      <div className="self-center text-lg font-medium leading-8 text-black">
                        Are you sure there are no edits from past job?
                      </div>
                      <div className="flex gap-2.5 mt-5 self-center text-base capitalize">
                        <button
                          onClick={() => setShowModal1(false)}
                          className="justify-center px-10 py-3 text-black whitespace-nowrap bg-white border border-amber-300 border-solid rounded-[124px]"
                        >
                          Cancel
                        </button>
                        <Link
                          to={"/PendingAcceptance"}
                          className="justify-center px-5 py-3 cursor-pointer text-white bg-red-500 rounded-[124px]"
                        >
                          Yes I’m Sure
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
          </>
        ) : null}
      </>

      <>
        {showModal2 ? (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-3xl mx-auto my-6">
                {/*content*/}
                <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-3xl focus:outline-none">
                  {/*header*/}
                  <div>
                    <div className="flex flex-col px-5  pb-6 text-center bg-white rounded-3xl max-w-[500px]">
                      <div
                        onClick={() => setShowModal2(false)}
                        className="flex self-end pt-2 text-2xl cursor-pointer"
                      >
                        &#10005;
                      </div>
                      <div className="self-center pt-2 text-lg font-medium leading-8 text-black">
                        Are you sure you want to mark no show? You can choose
                        emergency hire option to hire another bartender. If not,
                        just click on ‘mark no show’
                      </div>
                      <div className="flex gap-2.5 mt-5 self-center text-base capitalize">
                        <Link
                          to={"/gigDetail1"}
                          className="justify-center px-5 py-3 text-black whitespace-nowrap bg-white border border-amber-300 border-solid rounded-[124px]"
                        >
                          Emergency Hire
                        </Link>
                        <button
                          onClick={() => setShowModal2(false)}
                          className="justify-center px-5 py-3 cursor-pointer text-white bg-red-500 rounded-[124px]"
                        >
                          Mark ‘No show’
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
          </>
        ) : null}
      </>

      <Modal
        open={showModal3}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReviewModal
          setShowModal3={setShowModal3}
          activeEstab={activeEstab}
          setGivenRating={setGivenRating}
          givenrating={givenrating}
        />
      </Modal>
    </div>
  );
}

export default Payment1;
