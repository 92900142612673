import React from 'react'

function SEOLandingSection6() {
    return (
        <div className='min-h-[100vh] pb-10'>
            <div className='h-[100vh]'>
                <div className='relative flex flex-col items-center justify-center'>
                    <video class="absolute top-0 left-0 object-cover w-full h-full" autoplay loop muted>
                        <source src="/images/SEO-Landing_PlayBack.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className='relative z-10 flex flex-col items-center justify-center pt-8 text-white'>
                        <h1 className='text-4xl font-normal font-Righteous'>Building <span className='text-4xl font-Righteous text-error'>Relationships</span> with Bartenders</h1>
                        <p className='pt-3 font-poppins max-w-[45%] text-center text-base'>At Barooom, we believe in fostering long-term relationships between establishments and bartenders. Our platform not only helps you find immediate staffing solutions but also enables you to build a network of reliable professionals you can call upon in the future.</p>
                    </div>
                    <div className='relative z-10 flex justify-around w-full translate-y-[50%] '>
                        <div className='relative  bg-no-repeat bg-cover bg-mixolog w-[35rem] h-[18rem] rounded-xl shadow-xl'>
                            <div class="absolute inset-0 bg-[#F7B81F] bg-opacity-95 rounded-xl"></div>
                            <div class="relative z-10 text-white justify-center items-center h-full flex flex-col">
                                <h2 className='text-3xl font-normal font-Righteous'>Create a Talent Pool</h2>
                                <p className='px-4 pt-3 text-center text-white text-wrap font-poppins'>As you work with various bartenders, you can create a talent pool of your favorites. This allows you to quickly reach out to individuals who have already proven their skills and fit well with your establishment’s culture.
                                </p>
                            </div>
                        </div>
                        <div className='bg-white w-[35rem] h-[18rem] relative rounded-xl shadow-xl'>
                            <div class=" text-white justify-center items-center h-full flex flex-col">
                                <h2 className='text-3xl font-normal text-black font-Righteous'>Ongoing Communication</h2>
                                <p className='px-4 pt-3 text-center text-black text-wrap font-poppins'>We encourage open communication between you and the bartenders you hire. Discuss expectations, provide feedback, and maintain a dialogue to ensure a successful working relationship that benefits both parties.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='relative flex items-center justify-center px-40 max-lg:px-10'>
                <img src="/images/Side_SEO_Landing_01.svg" alt="Bartender_Image" className='w-auto h-[35rem]' />
                <div className=''>
                    <h1 className='text-4xl font-normal font-Righteous'>The <span className='text-4xl font-Righteous text-error'>Barooom</span> Experience</h1>
                    <p className='pt-3 font-poppins text-gray-1'>When you hire through Barooom, you can expect a seamless and enjoyable experience:</p>

                    <div className='flex flex-col gap-4 pt-4'>

                        <label className='flex'>
                            <img src="/images/Customer_Icon.svg" alt="Icon" className='w-auto h-16' />
                            <p className='font-normal font-poppins text-gray-1 '>
                                <span className='font-bold text-black font-poppins'>Professionalism:</span> Our bartenders arrive on time, dressed appropriately, and ready to provide exceptional service. They understand the importance of professionalism and are committed to representing your brand positively.
                            </p>
                        </label>
                        <label className='flex'>
                            <img src="/images/Customer_Icon.svg" alt="Icon" className='w-auto h-16' />
                            <p className='font-normal font-poppins text-gray-1 '>
                                <span className='font-bold text-black font-poppins'>Attention to Detail:</span> Bartenders hired through Barooom are trained to pay attention to every detail, from drink presentation to guest interactions. Their goal is to ensure that every aspect of your beverage service exceeds expectations.
                            </p>
                        </label>
                        <label className='flex'>
                            <img src="/images/Customer_Icon.svg" alt="Icon" className='w-auto h-16' />
                            <p className='font-normal font-poppins text-gray-1 '>
                                <span className='font-bold text-black font-poppins'>Problem-Solving Skills:</span> Events can be unpredictable, but our bartenders are equipped to handle any challenges that arise. Whether it’s managing unexpected crowd sizes or adjusting drink orders, they know how to adapt and maintain high-quality service.
                            </p>
                        </label>

                    </div>
                </div>
                <img src="/images/Side_Graphic_01.svg" alt="Bear_Glass" className='absolute mt-auto right-0 h-[25rem]' />
            </div>
        </div>
    )
}

export default SEOLandingSection6