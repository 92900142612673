import React, { useEffect, useState } from "react";
import WrapperDash from "../../../dashboard/WrapperDash";
import { Link, useNavigate, useParams } from "react-router-dom";
import Dropdown from "../../../dashboard/Bartender/Dropdown";
import Notification from "../../../dashboard/Bartender/Notification";
import DashHeader from "../../../dashboard/DashHeader";
import RightSideTile from "../../../dashboard/Bartender/mains/components/RightSideTile";
import { Switch } from "antd";
import style from "../../eventDeatail.module.css";
import dayjs from "dayjs";
import {
  ApplyForGig,
  CancleForGig,
  fetchGigDetails,
  getNewOpportunityforBart,
  toggleBartAvailability,
} from "../../../../axios/axios";
import { useSelector } from "react-redux";
import AboutGig from "./AboutGig";
import AboutEstab from "./AboutEstab";
import Benifits from "./Benifits";
import { toast } from "react-toastify";
import { formatNumber } from "../../../../utils/helper";
import StripeSetupModal from "../../../Modals/StripeSetupModal";

function GigsNewApply() {
  const user = useSelector((state) => state.user.user);
  const { gid } = useParams();
  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [message, setMessage] = useState("");
  const [jobdata, setJobData] = useState();
  const [activetab, setActiveTab] = useState(0);
  const [newOpp, setNewOpp] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const [data, setData] = useState({
    no: {
      prev: false,
      next: false,
      search: "",
      page: 1,
      total_page: 1,
      datas: [],
    },
  });

  const fetchNewOpportunity = () => {
    getNewOpportunityforBart({
      page: data.no.page,
      search: data.no.search,
      token: user?.token,
    })
      .then((result) => {
        let res = result.data.data;
        let his = res.history.slice(0, 3);

        setNewOpp({ ...res, history: his });
      })
      .catch((err) => {
        console.log("new Opp", err);
      });
  };

  const toggleAvailablity = () => {
    toggleBartAvailability({
      available: !isActive,
      token: user?.token,
    })
      .then((result) => {
        setIsActive(result.data.data);
      })
      .catch((err) => {
        if (err.type == 2) {
          if (err.message != "") {
            //set the message sent from the backend
          }
          setMessage(
            `You need to provide bank details and personal information for accepting payments before making your self available.`
          );
          setShowModal2(!showModal2);
        } else if (err.type == 5) {
          if (err.message == "") {
            //set the message sent from the backend
            toast.warning("User Profile Not Setup");
          }
          setMessage(
            "You need to provide bank details and personal information for accepting payments, before making your self available."
          );
          navigate("/profile?setup=true");
        } else {
          toast.error(err?.message | "Toggle Availability error");
        }
      });
  };

  const GigApply = () => {
    ApplyForGig({
      token: user?.token,
      job_id: gid,
    })
      .then((result) => {
        setShowModal1(true);
        toast.success(result?.data.message);

        fetchGigData();
        setTimeout(() => {
          setShowModal1(false);
        }, 1000);
      })
      .catch((err) => {
        if (err.type == 2) {
          if (err.message != "") {
            //set the message sent from the backend
          }
          setShowModal2(!showModal2);
        } else if (err.type == 5) {
          if (err.message == "") {
            //set the message sent from the backend
            toast.warning("User Profile Not Setup");
          }
          navigate("/profile?setup=true");
        } else {
          toast.error(err?.message | "");
        }
      });
  };

  const GigCancle = () => {
    CancleForGig({
      token: user?.token,
      job_id: gid,
    })
      .then((result) => {
        toast.success(result?.data?.message);
        fetchGigData();
        setShowModal(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const fetchGigData = () => {
    fetchGigDetails({
      Job_Id: gid,
      token: user?.token,
    })
      .then((result) => {
        setJobData(result.data[0]);
      })
      .catch((err) => {
        console.log("new Opp", err);
      });
  };

  useEffect(() => {
    fetchGigData();
    fetchNewOpportunity();
    return () => setJobData();
  }, []);

  useEffect(() => {
    if (showModal2 == false) setMessage("");
  }, [showModal2]);

  return (
    <WrapperDash>
      <DashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Gig</span>{" "}
              <span className="text-yellow-400">Details</span>
            </div>
          </>
        }
      />
      <div className="flex max-w-full gap-5 mt-7">
        {/* first */}
        <div className=" relative flex flex-col grow flex-2 items-start pt-5 pr-7 pb-20 pl-4 w-[] bg-white rounded-3xl shadow-2xl ">
          <div className="relative flex flex-col self-stretch w-full gap-5 px-5 py-5 overflow-hidden max-md:flex-wrap max-md:pl-5 max-md:max-w-full">
            <img
              loading="lazy"
              src="/images/completeProfile/Background-Image.png"
              alt="BackImg"
              className="absolute inset-0 rounded-xl size-full"
            />
            <div className="relative flex-auto max-md:max-w-full">
              <div className="flex gap-1 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col max-md:ml-0 max-md:w-full">
                  <img
                    loading="lazy"
                    src="/images/completeProfile/Bar-full.png"
                    className="shrink-0 max-w-full  border-2 border-white border-solid aspect-square w-[120px] max-md:mt-8"
                  />
                </div>
                <div className="flex flex-col ml-5 w-[71%] max-md:ml-0 max-md:w-full">
                  <div className="relative flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                    <div className="mt-1 text-lg font-semibold leading-6 tracking-tight text-yellow-400 max-md:max-w-full">
                      {jobdata?.establishmentid?.establishmentName}
                    </div>
                    <div className="mt-1 text-sm leading-5 text-white max-md:max-w-full">
                      {`${jobdata?.establishmentid?.location?.streetName}, ${jobdata?.establishmentid?.location?.city}, ${jobdata?.establishmentid?.location?.state}, ${jobdata?.establishmentid?.location?.postalCode}`}
                    </div>
                    <div className="flex w-full gap-4 mt-4 text-xs max-md:flex-wrap max-md:max-w-full">
                      <div className="flex gap-1.5 items-start self-start">
                        <img
                          loading="lazy"
                          src="/images/completeProfile/yellow-timer.png"
                          className="shrink-0 aspect-square w-[15px]"
                        />
                        <div className="flex flex-col">
                          <div className="text-white text-opacity-80">
                            Shift Time
                          </div>
                          <div className="mt-1 font-medium text-white">
                            {dayjs(jobdata?.startTime, "HH:mm").format(
                              "hh:mm A"
                            )}{" "}
                            -{" "}
                            {dayjs(jobdata?.endTime, "HH:mm").format("hh:mm A")}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-auto gap-6">
                        <div className="flex gap-1.5 items-start self-start">
                          <img
                            loading="lazy"
                            src="/images/completeProfile/yellow-timer.png"
                            className="shrink-0 aspect-square w-[15px]"
                          />
                          <div className="flex flex-col">
                            <div className="text-white text-opacity-80">
                              Distance
                            </div>
                            <div className="mt-1 font-medium text-white">
                              {jobdata?.distance
                                ? formatNumber(jobdata?.distance)
                                : ""}{" "}
                              miles
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div className="flex gap-1.5 whitespace-nowrap text-white text-opacity-80">
                            <img
                              loading="lazy"
                              src="/images/completeProfile/yellow-user.png"
                              className="shrink-0 aspect-square w-[17px]"
                            />
                            <div className="flex-auto my-auto">
                              Accommodates
                            </div>
                          </div>
                          <div className="self-center mt-1.5 font-medium text-white">
                            {jobdata?.establishmentid?.accommodates}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <button className=" absolute top-3 right-3 bg-white flex w-fit px-3 py-1  rounded-[110px] h-fit ">
                <img
                  loading="lazy"
                  src="/images/completeProfile/Vector.png"
                  className=" aspect-square w-[18px] h-[18px]   max-md:mt-10 max-md:ml-2.5"
                />

                <div className="pl-1 font-semibold">{jobdata?.rating}</div>
              </button>
              <div
                className="absolute bottom-4 right-4"
                // onClick={() => navigate(`${}`)}
              >
                <img
                  loading="lazy"
                  src="/images/completeProfile/white-chat.png"
                  className="cursor-pointer ml-5 aspect-square w-[50px] max-md:mt-10 max-md:ml-2.5"
                  onClick={() =>
                    navigate(`/dashboard/chat/`, {
                      state: { uid: jobdata?.uid },
                      Tab: 1,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex gap-10 justify-between self-center px-1 pr-5 py-1 mt-3.5  text-sm leading-5 bg-white border border-gray-200 border-solid rounded-[100px] max-md:flex-wrap">
            <div className="flex gap-5">
              <div
                className={`${style.sliderBtn} ${
                  activetab === 0 && style.sliderBtnActive
                }`}
                onClick={() => setActiveTab(0)}
              >
                {activetab === 0 && (
                  <img
                    loading="lazy"
                    src="/images/completeProfile/cocktail.png"
                    className="w-6 shrink-0 aspect-square"
                  />
                )}

                <button>About The Gig</button>
              </div>
              <div
                onClick={() => setActiveTab(1)}
                className={`${style.sliderBtn} ${
                  activetab === 1 && style.sliderBtnActive
                }`}
              >
                {activetab === 1 && (
                  <img
                    loading="lazy"
                    src="/images/completeProfile/cocktail.png"
                    className="w-6 shrink-0 aspect-square"
                  />
                )}
                About Establishment
              </div>
              <div
                className={`${style.sliderBtn} ${
                  activetab === 2 && style.sliderBtnActive
                }`}
                onClick={() => setActiveTab(2)}
              >
                {activetab === 2 && (
                  <img
                    loading="lazy"
                    src="/images/completeProfile/cocktail.png"
                    className="w-6 shrink-0 aspect-square"
                  />
                )}
                Benefits
              </div>
            </div>
          </div>
          {activetab === 0 && <AboutGig jobdata={jobdata} />}
          {activetab === 1 && <AboutEstab jobdata={jobdata} />}
          {activetab === 2 && <Benifits jobdata={jobdata} />}

          {jobdata?.assigned &&
          jobdata.assigned.some(
            (assigned) => assigned.bartender_id._id === user._id
          ) ? (
            ""
          ) : jobdata?.applied &&
            jobdata.applied.some(
              (applied) => applied.bartender_id._id === user._id
            ) ? (
            <div
              className="justify-center self-center px-5 py-5 mt-10 cursor-pointer w-[70%] text-sm text-center text-black bg-amber-300 rounded-[92.207px] max-md:max-w-full"
              onClick={() => setShowModal(true)}
            >
              <button>Cancel Applied Application</button>
            </div>
          ) : (
            <div
              className="justify-center self-center cursor-pointer px-5 py-5 mt-10 w-[70%] text-sm text-center text-black bg-amber-300 rounded-[92.207px] max-md:max-w-full"
              onClick={() => GigApply()}
            >
              <button>Apply</button>
            </div>
          )}
        </div>
        {/* second */}
        <div className=" max-md:flex-wrap flex-2">
          <div className="bg-white rounded-3xl shadow-2xl  max-w-[355px] ">
            <div className="p-4 ">
              <div className="flex gap-5 justify-between pt-2 pr-3.5 max-xl:flex-wrap max-md:max-w-full">
                <div
                  className={`flex-auto text-[18px] text-black} ${style.header}`}
                >
                  Available Now
                </div>

                <Switch
                  checked={isActive}
                  onChange={() => toggleAvailablity()}
                  style={{ backgroundColor: isActive ? "red" : "gray" }}
                  defaultChecked
                />

                {/* <div className="flex flex-col justify-center py-1 pr-1.5 pl-8 bg-red-500 rounded-[56.25px] max-md:pl-5">
                  <div className="shrink-0 bg-white rounded-full h-[23px] w-[23px]" />
                </div> */}
              </div>
              <div className="mt-4 text-sm tracking-tighter text-neutral-400 max-xl:flex-wrap max-md:max-w-full">
                Establishments will see your expected hourly rate, but their
                posting may offer a higher/lower offer for a gig, you choose
                whether to accept or not at time of posting.
              </div>
              <div className="mt-8 ">
                {newOpp.history.map((elem, ind) => {
                  return (
                    <RightSideTile
                      key={`righttile${ind}`}
                      dayjs={dayjs}
                      elem={elem}
                      handleApplyGig={GigApply}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="flex flex-col px-7 py-5 text-center bg-white rounded-3xl max-w-[432px]">
                <div className="self-center text-base font-medium leading-8 text-black">
                  Are you sure you want to
                  <br />
                  cancel your gig?
                </div>
                <div className="flex gap-2.5 mt-4 text-base capitalize">
                  <button
                    onClick={() => setShowModal(false)}
                    className="justify-center px-8 py-3 text-black whitespace-nowrap bg-white border border-amber-300 border-solid rounded-[121px]"
                  >
                    Cancel
                  </button>
                  <button className="justify-center px-5 py-3 text-black bg-amber-300 rounded-[121px]">
                    <button onClick={GigCancle}>Yes, I’m Sure </button>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}

      {showModal1 && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              {/*content*/}
              <div className="relative flex flex-col w-full bg-white outline-none rounded-xl focus:outline-none">
                {/*header*/}
                <button
                  className="self-end pt-3 pr-4 text-2xl"
                  onClick={() => setShowModal1(false)}
                >
                  {" "}
                  &#10005;
                </button>
                <img
                  src="/images/Success1.png"
                  className="w-[400px] px-20 pt-10 pb-20"
                />
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      )}

      {showModal2 && (
        <StripeSetupModal
          ModalShow={showModal2}
          setModalShow={setShowModal2}
          message={message}
        />
      )}
    </WrapperDash>
  );
}

export default GigsNewApply;
