import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function PageHeaderNav({ textColor, hamColor }) {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const NavLinks = [
        { label: 'Home', url: '/' },
        { label: 'About', url: '/about-us' },
        { label: 'Service', url: '/about-us' },
        { label: 'Blog', url: '/blogs' },
        { label: 'Contact', url: '/contact-us' },
    ];

    const serviceOptions = [
        { label: 'For Bartender', url: '/bartending-jobs-in-charlotte' },
        { label: 'For Establishment', url: '/hire-a-bartender-in-charlotte' },
    ];

    const handleServiceClick = (url) => {
        setIsDropdownOpen(false); // Close the dropdown when an option is clicked
        navigate(url);
    };
    const handleMenuToggle = () => {
        setShowMenu(!showMenu)
    };

    return (
        <div className='flex w-[100vw] justify-between px-16 pt-5 items-center'>
            <img className='w-24 h-20' src="./images/barooom.png" alt="logo" />
            <button className="hidden max-sm:block focus:outline-none" onClick={handleMenuToggle}>
                <svg
                    className={`w-6 h-6 ${hamColor || 'text-white'}`}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
            <section className='flex items-center gap-10 max-sm:hidden'>
                {NavLinks.map((ele, ind) => (
                    ind != 2 ? <p
                        className={`${!textColor ? 'text-white' : 'textColor'} cursor-pointer`}
                        onClick={() => navigate(ele.url)}
                        key={ele.label}
                    >
                        {ele.label}
                    </p> :
                        <div className='relative' key={ind}
                            onMouseEnter={() => setIsDropdownOpen(true)}
                        // onMouseLeave={() => setIsDropdownOpen(false)}
                        >
                            <p className={`flex items-center ${!textColor ? 'text-white' : 'textColor'} cursor-pointer`}>
                                Service
                            </p>

                            {isDropdownOpen && (
                                <div className='absolute left-0 z-10 text-black bg-white rounded shadow-lg top-8' onMouseLeave={() => setIsDropdownOpen(false)}>
                                    {serviceOptions.map((option) => (
                                        <p
                                            key={option.label}
                                            className='px-4 py-2 cursor-pointer text-nowrap hover:bg-gray-200 hover:rounded-xl'
                                            onClick={() => handleServiceClick(option.url)}
                                        >
                                            {option.label}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>
                ))}


            </section>

            <button className='border-4 border-[#FF33336B] rounded-[25px] h-fit py-3 px-2 bg-[#FF3333] text-white font-poppins font-bold text-center max-sm:hidden'>
                Sign Up for Early Access
            </button>

            <div
                className={`fixed top-0 left-0 h-screen w-screen bg-white z-50 transition-all duration-300 ease-in-out ${showMenu ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full'
                    }`}
            >
                <div className="flex flex-col items-center px-6 pt-16">
                    {/* Close button for modal */}
                    <button className="absolute top-5 right-5 focus:outline-none" onClick={handleMenuToggle}>
                        <svg
                            className="w-6 h-6 text-black"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6 18L18 6M6 6L18 18"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>

                    {NavLinks.map((ele, ind) => (
                        ind != 2 ? <p
                            key={ele.label}
                            className="my-4 text-lg font-medium cursor-pointer"
                            onClick={() => {
                                handleMenuToggle(); // Close the modal after clicking a link
                                navigate(ele.url);
                            }}
                        >
                            {ele.label}
                        </p> :
                            <>
                                <p
                                    key={ele.label}
                                    className="my-4 text-lg font-medium cursor-pointer"
                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                >
                                    {ele.label}
                                </p>
                                {isDropdownOpen && (
                                    <div className='w-full text-center bg-gray-200 rounded-xl'>
                                        {serviceOptions.map((option) => (
                                            <p
                                                key={option.label}
                                                className="my-4 text-lg font-medium cursor-pointer"
                                                onClick={() => handleServiceClick(option.url)}
                                            >
                                                {option.label}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </>
                    ))}

                </div>
            </div>
        </div>
    );
}

export default PageHeaderNav;
