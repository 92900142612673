import React from 'react'
import EstabLanding01 from '../components/SEOLaning2/EstabLanding01'
import NewFooter from '../components/landing/NewFooter'
import EstabLanding02 from '../components/SEOLaning2/EstabLanding02'
import EstabLanding03 from '../components/SEOLaning2/EstabLanding03'
import EstabLanding04 from '../components/SEOLaning2/EstabLanding04'
import EstabLanding05 from '../components/SEOLaning2/EstabLanding05'
import EstabLanding06 from '../components/SEOLaning2/EstabLanding06'

function EstabLandigSEO() {
    return (
        <div>
            <main className='no-scrollbar'>
                <EstabLanding01 />
                <EstabLanding02 />
                <EstabLanding03 />
                <EstabLanding04 />
                <EstabLanding05 />
                <EstabLanding06 />
                <NewFooter />

            </main>
        </div>
    )
}

export default EstabLandigSEO