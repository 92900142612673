import React from "react";
import { getTimeDifferenceFromNow } from "../../utils/TimeFormatting";
import dayjs from "dayjs";
import configuration from "../../config/configuration";
import { useNavigate } from "react-router-dom";
import { handleFloat } from "../../utils/helper";
function JobCards({ elem, index, Jtype }) {
  const navigate = useNavigate();

  return (
    <div
      className="relative flex w-[290px] h-fit p-1 flex-col  rounded-xl  border border-solid bg-zinc-50 border-neutral-200 max-md:mt-5"
      key={`job_${elem?._id}_${index}`}>
      <div
        loading="lazy"
        onClick={() => navigate(`/gig/${elem._id}`, { state: Jtype })}
        style={{
          backgroundImage: `url('${configuration.cdnFront}/${elem?.establishmentid?.EprofileImg}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="w-full shadow-lg aspect-[2] rounded-xl"
      />
      {Jtype == 1 || Jtype == 2 ? (
        <div
          className="absolute top-4 right-4 px-3 py-[1px] text-xs font-medium text-black whitespace-nowrap bg-white rounded-xl border border-amber-300 border-solid"
          onClick={() => navigate(`/gig/${elem._id}`, { state: Jtype })}>
          {Jtype == 1 && (
            <p>
              {dayjs(elem?.startdate).toDate() > dayjs().toDate()
                ? "Upcomming"
                : "Ongoing"}
            </p>
          )}
          {Jtype == 2 && <p>{elem?.applied?.length || 0} Application</p>}
        </div>
      ) : (
        ""
      )}
      <div
        className="flex flex-col gap-1 mt-2.5 font-medium"
        onClick={() => navigate(`/gig/${elem._id}`, { state: Jtype })}>
        <div className="tracking-tighter text-black grow text-l whitespace-nowrap">
          {elem?.establishmentid?.establishmentName}{" "}
          {Jtype != 3 && (
            <span className="text-xs text-[#FF8642]">
              {getTimeDifferenceFromNow(elem?.startdate)}
            </span>
          )}
        </div>
        <div className="text-xs tracking-tighter text-black grow whitespace-nowrap">
          {elem?.jobTitle}
        </div>
      </div>
      <div
        className="mt-[1px] text-xs leading-5 whitespace-nowrap text-neutral-400"
        onClick={() => navigate(`/gig/${elem._id}`, { state: Jtype })}>
        {`${elem?.establishmentid?.location?.city}, ${elem?.establishmentid?.location?.state}`}
      </div>
      <div className="flex gap-1 mt-1 text-xs text-neutral-700">
        <img
          loading="lazy"
          src="/images/completeProfile/clock.png"
          className=" w-3 h-3 mt-[2px]"
        />
        <div className="flex-auto tracking-tighter text-[11px] whitespace-nowrap">
          Shift Dates: {dayjs(elem.startdate).format("ddd MMM DD YYYY")} -{" "}
          {dayjs(elem.enddate).format("ddd MMM DD YYYY")}
        </div>
      </div>
      <div
        className="flex gap-1 mt-1 text-xs text-neutral-700"
        onClick={() => navigate(`/gig/${elem._id}`, { state: Jtype })}>
        <img
          loading="lazy"
          src="/images/completeProfile/clock.png"
          className=" w-3 h-3 mt-[2px]"
        />
        <div className="flex-auto tracking-tigter text-[11px]">
          Shift Time: {dayjs(elem.startdate).format("hh:mm A")} -{" "}
          {dayjs(elem.enddate).format("hh:mm A")} | Pay: $
          {handleFloat(elem?.payHourly)}/hr
        </div>
      </div>
      {/* {Jtype == 3 && (
        <div>
          {elem?.isPaid ? (
            <p className="text-[11px] underline">View Invoice</p>
          ) : (
            <div className="flex items-center gap-2">
              <img
                src="/images/red_dollor.svg"
                className="w-3 h-3"
                alt="Dollor_Icon"
              />
              <p className="text-error text-[11px]">Payment Not Processed</p>
            </div>
          )}
        </div>
      )} */}
    </div>
  );
}

export default JobCards;
