import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AuthenticatedLayout = ({ children }) => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const { gid } = useParams();
  const [allowView, setAllowView] = useState(false);

  useEffect(() => {
    if (user === null) {
      if (gid && gid != " ") {
        navigate(`/login/${gid}`);
      } else {
        navigate("/");
      }
    } else {
      if (user.account_status == 3 || user.account_status == 4) {
        // navigate("/setup");
      } else if (user.role == 2 && !user.profileSetup) {
        navigate("/setup");
      } else {
        setAllowView(true);
      }
    }
  }, [user]);

  return <>{allowView && children}</>;
};

export default AuthenticatedLayout;
