import { useEffect, useState } from "react";
import styles from "./BarEdit.module.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { CircularProgress, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { Button } from "antd";
import {
  fetchUserData,
  updateBartBasic,
  updateBartDesig,
  updateBartSsn,
} from "../../axios/axios";
import { updateUser } from "../../redux/userSlice";
import { ensureNumber, ensureString } from "../../utils/inputFormatter";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function BarEdit5() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [errormsg, setErrorMsg] = useState({});

  const changeInputHandler = ({ name, value }) => {
    let x = { ...localData };
    x[name] = value;

    setLocalData(x);
  };

  const [localData, setLocalData] = useState({
    ssNumber: "",
  });

  const submitHandler = () => {
    setApiLoader(true)
    updateBartSsn({ data: localData, token: user.token })
      .then((result) => {
        toast.success(result?.data?.message);
        setApiLoader(false)
        navigate("/profile");
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setApiLoader(false)

        console.log("err", err);
      });
  };

  const getUserData = () => {
    fetchUserData({ token: user?.token })
      .then((result) => {
        const { ssNumber } = result.data.data;
        setLocalData({ ssNumber: ssNumber });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <div className="flex flex-col max-w-full w-[1299px]">
      <div className="flex flex-col items-center mb-36 max-w-full w-[1293px]  max-md:my-10">
        <div
          className={`text-2xl leading-9 text-center text-black whitespace-nowrap} ${styles.header}`}>
          Background Check
        </div>
        <div className="self-stretch mt-1 text-[14px] text-center text-neutral-600 max-md:max-w-full">
          {/* The background check is optional and do not feel obligated to enter
          your SS# now. You will have a higher certification to the employers if
          we do provide a background check. */}
        </div>
        <div className="flex gap-4 px-5  py-3.5 mt-[75px] max-w-full text-xs bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] w-[639px] max-md:flex-wrap max-md:mt-10">
          <img
            loading="lazy"
            src="/images/completeProfile/tick-circle-solid.png"
            className="my-auto aspect-square w-[22px]"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full">
            <div className="text-left text-black max-md:max-w-full">
              SS Number
            </div>
            <div className="mt-2 text-left text-zinc-500 max-md:max-w-full">
              <input
                className="w-full border-none outline-none"
                type="text"
                placeholder="Enter your SS Number"
                value={localData.ssNumber}
                name="ssNumber"
                onChange={(e) =>
                  changeInputHandler({
                    name: e.target.name,
                    value: ensureNumber({
                      value: e.target.value,
                      maxLength: 9,
                    }),
                  })
                }
              />
            </div>
          </div>
        </div>
        <Button
          loading={apiLoader}
          type="text"
          style={{ height: "auto" }}
          onClick={submitHandler}
          className="justify-center px-5 py-5 mt-9 max-w-full text-sm text-center text-black bg-amber-300 rounded-[92.207px] w-[639px] max-md:max-w-full">
          Save & Next
        </Button>
      </div>
    </div>
  );
}

export default BarEdit5;
