import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import configuration from "../../../config/configuration";
import { useSelector } from "react-redux";
import { GetBartenderProfileStatus } from "../../../axios/axios";

function EditProfile({ userdata, selectedImage, handleFileChange }) {
  const { token, pic } = useSelector((state) => state.user.user);
  const [MissingIndex, setMissingIndex] = useState([]);

  const FetchBartenderProfileStatus = async () => {
    try {
      const data = await GetBartenderProfileStatus({ token });
      const { missingDetails } = data.data;
      setMissingIndex(missingDetails);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!token) return;
    FetchBartenderProfileStatus();
  }, [pic]);

  const navigate = useNavigate();
  const sections = [
    {
      navigateLink: "/profile/bartender-edit",
      passactive: 1,
      label: "Basic Info",
    },
    {
      navigateLink: "/profile/bartender-edit",
      passactive: 2,
      label: "Designation",
    },
    {
      navigateLink: "/profile/bartender-edit",
      passactive: 3,
      label: "Skills & Interest",
    },
    {
      navigateLink: "/profile/bartender-edit",
      passactive: 4,
      label: "Links & Documents",
    },
    {
      navigateLink: "/profile/bartender-edit",
      passactive: 5,
      label: "Background Check",
    },
    {
      navigateLink: "/profile/bartender-edit",
      passactive: 6,
      label: "Payment Setup",
    },
  ];

  return (
    <div className="flex flex-col py-4 mx-2 px-8 w-[390px] max-w-lg text-base text-black max-md:px-5">
      <div className="flex flex-col items-center">
        <div className="relative overflow-hidden">
          {selectedImage != null ? (
            <img
              loading="lazy"
              src={selectedImage}
              alt="selected Img"
              className="h-32 w-32 rounded-[50%]"
            />
          ) : (
            <img
              loading="lazy"
              src={
                userdata?.profileImg
                  ? `${configuration.cdnFront}/${userdata?.profileImg}`
                  : "/images/Dummy_User.jpg"
              }
              className="h-32 w-32 rounded-[50%]"
              alt="User Profile Img"
            />
          )}
          <div className="absolute bottom-0 right-0">
            <img
              loading="lazy"
              src="/images/Camera_Icon.svg"
              alt="Camera Icon"
            />
          </div>
          <input
            type="file"
            className="absolute top-0 w-full h-full opacity-0"
            onChange={(e) => handleFileChange(e)}
          />
          <p
            className={`${
              MissingIndex.some(
                (elem) =>
                  elem.index === 1 && elem.detail.includes("Profile Image")
              )
                ? "absolute bg-yellow-border-1 text-sm px-7 top-4 left-0 -translate-x-8 transform -rotate-45 text-[#FFAF00] text-center"
                : "hidden"
            }`}
          >
            Pending
          </p>
        </div>
        <p className="mt-1 text-lg text-red-400">{userdata?.uid?.name}</p>
      </div>

      {sections.map((section, index) => (
        <div
          key={index}
          className="relative flex justify-between gap-3 py-2 mt-5 overflow-hidden border-2 border-solid cursor-pointer px-7 rounded-xl border-amber-300 max-md:flex-wrap max-md:px-5 max-md:max-w-full"
          onClick={() =>
            navigate(section.navigateLink, {
              state: { passactive: section.passactive },
            })
          }
        >
          <div className="min-w-[150px]">{section.label}</div>
          <div
            className={`${
              MissingIndex.some(
                (elem) =>
                  elem.index === index + 1 &&
                  (index !== 0 ||
                    (elem.detail.includes("Profile Image") &&
                      elem.detail.length > 1))
              )
                ? "text-sm border-yellow-background border-[1.2px] text-[#FFAF00] rounded-xl bg-yellow-border-1 px-2 text-center flex justify-center items-center"
                : "hidden"
            }`}
          >
            <p>Pending</p>
          </div>
          <span className="text-xl">&#10095;</span>
        </div>
      ))}
    </div>
  );
}

export default EditProfile;
