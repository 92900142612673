import axios from "axios";
import { store } from "../redux/store";
import {
  setAlreadySigned,
  setServerOffline,
  setUnAuthorized,
} from "../redux/globalSlice";
import configuration from "../config/configuration";

export const Axios = axios.create({
  baseURL: configuration.apiUrl,
});

Axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      if (error.message === "Network Error") {
        store.dispatch(setServerOffline(true)); // Update global state to show offline message
        console.log("Server Seems Offline");
      } else {
        console.error("Unexpected error:", error.message);
      }
    }
    if (error.response) {
      if (error.response.status === 403) {
        if (error.response.data && error.response.data.type === 2) {
          store.dispatch(setAlreadySigned(true));
        }
      } else if (
        error.response.status === 403 ||
        error.response.status === 401
      ) {
        store.dispatch(setUnAuthorized(true));
      }
    }

    return Promise.reject(error);
  }
);
