import React, { useEffect, useRef } from "react";
import Wrappers from "../components/complete_profile/Wrappers";
import { Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function VerificationPending() {
  const timeoutRef = useRef(null);
  const toastTimeout = useRef(null);
  const navigate = useNavigate();
  const startTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      navigate('/dashboard')
    }, 2000);
  }

  const startToastTimeout = () => {
    toastTimeout.current = setTimeout(() => {
      toast.success("Redirecting to dashboard...")
      startTimeout()
    }, 500);
  }

  useEffect(() => {
    startToastTimeout()

    return () => {
      clearTimeout(timeoutRef.current)
      clearTimeout(toastTimeout.current)
    }
  }, [])


  return (
    <Wrappers
      activeTab={0}
      setActiveTab={() => null}
      showHeader={false}
      totalSteps={6}>
      <div style={{ padding: "20px 20px 100px 20px" }}>
        <Alert
          showIcon={true}
          type="warning"
          message={`Verification under Process.`}
        />

        <Alert
          showIcon={true}
          type="info"
          message={`Verification may take between 5 minutes and 3 days. As soon as your account is verified, you will receive an email.`}
        />
      </div>
    </Wrappers>
  );
}
{
  /* message={`Verification may take upto 5 minutes to 3 days. You will receive an email once your Account is verified.`} /> */
}
