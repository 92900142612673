import { Player } from '@lottiefiles/react-lottie-player';
import React, { Component } from 'react'

export default class LottiePlayer extends Component {
    constructor(props) {
        super(props);
        this.state = { lottie: null, src: props.src }; // initialize your state
    }

    render() {
        return (
            <Player
                lottieRef={instance => {
                    this.setState({ lottie: instance }); // the lottie instance is returned in the argument of this prop. set it to your local state
                }}
                autoplay={true}
                loop={true}
                controls={true}
                src={this.state.src}
                style={{ height: '300px', width: '300px' }}
            ></Player>
        );
    }
}
