import React, { useRef } from "react";
import { useState, useEffect } from "react";

import { useLocation, useParams } from "react-router-dom";
import MessagesSection from "./MessagesSection";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import configuration from "../../config/configuration";
import setupNotificationListener from "../NotificaionHandler";
import {
  blockUser,
  getChatMessages,
  getChatRooms,
  getContent,
  initilizeChat,
  reportUser,
} from "../../axios/axios";
import InternalBar from "../internalBar/InternalBar";
import { PiUserCircleThin } from "react-icons/pi";
import { useSwipeable } from "react-swipeable";

function Chatroom() {
  const user = useSelector((state) => state.user.user);
  const [showModal2, setModalShow2] = useState(false);
  const [showModal3, setModalShow3] = useState(false);
  const [change, setChange] = useState(false);
  const [tab, setTab] = useState(0);
  const location = useLocation();
  const [urluser, seturluser] = useState("");
  const [search, setSearch] = useState("");
  const [myTimeOut, setMytimeOut] = useState();
  const [inputtext, setInputText] = useState("");
  const [reports, setReports] = useState([]);
  const [selReport, setSelReports] = useState();
  const [alsoBlock, setalsoBlock] = useState(false);

  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const [activeuser, setActiveUser] = useState({
    pic: "",
    name: "",
    _id: "",
    role: "",
    chatId: "",
    isBlocked: "",
    blockedBy: "",
  });

  const [msgs, setMsgs] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const InitlizeChat = () => {
    initilizeChat({ token: user?.token, to: urluser || location.state?.uid })
      .then((res) => {
        const chatdata = res?.data;
        const otherUser =
          chatdata.from == user._id ? chatdata.toUser : chatdata.fromUser;

        setActiveUser({
          _id: otherUser?._id,
          name: otherUser?.name,
          pic: otherUser?.pic,
          role: otherUser?.role,
          chatId: res?.data?._id,
          //   isBlocked: res?.data?.isBlocked,
          //   blockedBy: res?.data?.blockedBy,
        });

        getChatMessages({
          token: user?.token,
          page: data.page,
          search: search,
          chatId: res?.data?._id,
        })
          .then((result) => {
            let res = result.data;
            let x = { ...data };
            x = {
              ...x,
              next: res.next,
              prev: res.prev,
              history: [...res.history],
            };

            setMsgs(x);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => toast.error(err?.data?.message));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setTab(0),
    onSwipedRight: () => setTab(1),
  });

  const handleActiveUser = (chatdata) => {
    if (urluser) {
      seturluser("");
    }
    if (chatdata?._id != activeuser.chatId) {
      const otherUser =
        chatdata.from == user._id ? chatdata.toUser : chatdata.fromUser;
      setMsgs({
        prev: false,
        next: false,
        search: "",
        page: 1,
        total_page: 1,
        history: [],
      });
      setActiveUser({
        _id: otherUser._id,
        name: otherUser?.name,
        pic: otherUser?.pic,
        role: otherUser.role,
        chatId: chatdata._id,
        // isBlocked: chatdata.isBlocked,
        //   blockedBy: chatdata.blockedBy,
      });
      // Update the unreadcount for the specific chat in history
      setData((prevState) => ({
        ...prevState,
        history: prevState.history.map((chat) =>
          chat._id === chatdata._id ? { ...chat, unreadcount: 0 } : chat
        ),
      }));
    }
  };

  const formatMsgs = ({ msgData }) => {
    if (Array.isArray(msgData)) {
      return msgData.map(
        ({ chatId, conversationId, from, fromUsr, text, Msg, createdAt }) => ({
          chatId: chatId || conversationId,
          from: from || fromUsr,
          text: text || Msg,
          createdAt: createdAt || new Date().toISOString(),
        })
      );
    } else {
      let { chatId, conversationId, from, fromUsr, text, Msg, createdAt } =
        msgData;
      return {
        chatId: chatId || conversationId,
        from: from || fromUsr,
        text: text || Msg,
        createdAt: createdAt || new Date().toISOString(),
      };
    }
  };

  const fetchChatMsgs = ({ _id, activePg }) => {
    let chatid = _id ? _id : activeuser.chatId;
    getChatMessages({
      token: user?.token,
      page: activePg ? activePg : msgs.page,
      search: search,
      chatId: chatid,
    })
      .then((result) => {
        let res = result.data;
        let x = { ...msgs };
        const formattedMessages = formatMsgs({ msgData: res.history });
        setActiveUser({
          ...activeuser,
          isBlocked: res?.isBlocked,
          blockedBy: res?.blockedBy,
        });
        x = {
          ...x,
          next: res.next,
          total_page: res.total_page,
          total_page: res.total_page,
          page: res.page,
          prev: res.prev,
          history: [...formattedMessages, ...x.history],
        };
        setMsgs(x);
      })
      .catch((err) => console.log(err));
  };

  const fetchChatRooms = () => {
    getChatRooms({
      token: user?.token,
      page: data.page,
      search: search,
      tab: user?.role == 2 ? 1 : tab,
    })
      .then((result) => {
        let res = result.data;
        let x = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setData(x);

        // if (!activeuser._id && !urluser) {
        //   handleActiveUser(res?.history?.[0]);
        // }
      })
      .catch((err) => console.log(err));
  };

  const fetchReports = () => {
    getContent({ type: "Report" })
      .then((result) => {
        setReports(result.data.data["Report"]);
      })
      .catch((e) => {
        console.error(e.message);
      });
  };

  const handleImageShow = (elem) => {
    let some = elem.from == user._id ? elem?.toUser?.pic : elem?.fromUser?.pic;
    return some || false;
  };

  const checkBoxHandler = ({ checked, value }) => {
    console.log(value, checked);

    if (checked) {
      setSelReports(value);
    } else {
      setSelReports(null);
    }
  };

  const ReportUser = () => {
    reportUser({
      token: user?.token,
      complaint: selReport,
      reported: activeuser._id,
    })
      .then((result) => {
        toast.success(result?.message);
        // blockUsr(true);
        if (alsoBlock) {
          blockUsr(alsoBlock);
          setalsoBlock(false);
        }
        setModalShow2(false);
        setSelReports("");
      })
      .catch((err) => console.log(err));
  };

  const blockUsr = (action) => {
    blockUser({ token: user?.token, action, chatId: activeuser?.chatId })
      .then((res) => {
        toast.success(res.message);
        setActiveUser({ ...activeuser, isBlocked: true, blockedBy: user?._id });
        setModalShow3(false);
      })
      .catch((err) => toast.error(err.data.message));
  };

  useEffect(() => {
    if (!location.state?.uid) {
      fetchChatRooms();
    }
    if (location.state?.uid) {
      InitlizeChat();
    }
  }, [tab]);

  const onChatMessage = ({ data }) => {
    if (data.fromUsr !== user._id && activeuser._id == data.fromUsr) {
      setMsgs((prevMsgs) => ({
        ...prevMsgs,
        history: [...prevMsgs.history, formatMsgs({ msgData: data })],
      }));
      setChange(!change);
    }
  };

  const formatNotificationDate = ({ othertime }) => {
    const now = dayjs();
    const notificationDate = dayjs(othertime);

    if (now.isSame(notificationDate, "day")) {
      return "Today at " + notificationDate.format("h:mm A");
    } else if (now.subtract(1, "day").isSame(notificationDate, "day")) {
      return "Yesterday at " + notificationDate.format("h:mm A");
    } else {
      return notificationDate.format("dddd [at] h:mm A");
    }
  };

  const NavItems = [
    { index: 0, label: "Bartender" },
    { index: 1, label: "Establishment" },
  ];
  const handelTabClick = (ind) => {
    setTab(ind);
  };

  useEffect(() => {
    setupNotificationListener({ onChatMessage });
  }, []);

  useEffect(() => {
    if (!activeuser._id) return;
    fetchChatMsgs({ _id: activeuser.chatId });
  }, [activeuser._id]);

  useEffect(() => {
    if (reports.length > 0) return;
    fetchReports();
  }, []);

  useEffect(() => {
    clearTimeout(myTimeOut);
    const timeout = setTimeout(() => {
      fetchChatRooms();
    }, 700);
    setMytimeOut(timeout);
  }, [search]);

  return (
    <>
      <div className="flex gap-4 mt-5 h-[85%] w-full">
        <div className="p-4 bg-white flex-col rounded-3xl shadow-2xl overflow-hidden w-[25%]">
          {user?.role == 1 && (
            <InternalBar
              type={3}
              NavItems={NavItems}
              onClick={handelTabClick}
              tab={tab}
            />
          )}
          <div className="flex gap-2 px-2 py-3.5 mt-5 text-base leading-6 rounded-xl bg-neutral-100 text-stone-300 ">
            <img
              loading="lazy"
              src="/images/search.png"
              className="shrink-0 aspect-square w-[14px] py-1"
            />
            <div className="flex-auto">
              <input
                type="text"
                value={search}
                className="w-full text-black outline-none bg-neutral-100"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>

          <div
            className={`h-full overflow-y-scroll no-scrollbar mt-2 ${
              user?.role == 1 ? "pb-40" : "pb-20"
            }`}
          >
            {data.history.map((elem, ind) => {
              return (
                <div
                  className={`relative flex self-center gap-0 cursor-pointer py-3 px-1 rounded-xl ${
                    activeuser.chatId == elem?._id
                      ? user?.role == 1
                        ? "bg-yellow-200"
                        : "bg-red-200"
                      : "bg-white"
                  }`}
                  key={`Msg_${elem._id}_${ind}`}
                >
                  <div className="flex" onClick={() => handleActiveUser(elem)}>
                    {handleImageShow(elem) ? (
                      <img
                        loading="lazy"
                        src={
                          `${configuration.cdnFront}/${
                            elem.from == user._id
                              ? elem?.toUser?.pic
                              : elem?.fromUser?.pic
                          }` || ""
                        }
                        className="shrink-0 self-start rounded-full aspect-square w-[52px]"
                      />
                    ) : (
                      <PiUserCircleThin
                        style={{ width: "52px", height: "52px" }}
                      />
                    )}
                    <div className="flex w-full px-5 grow shrink-0 basis-0">
                      <div>
                        <div className="flex gap-5 justify-between leading-[160%]">
                          <div className="text-sm text-black">
                            {elem.from == user._id
                              ? elem.toUser?.name
                              : elem.fromUser?.name}
                          </div>
                        </div>
                        <div className=" text-[13px] leading-6 text-stone-500">
                          {elem.lastMessage &&
                            `${elem?.lastMessage.substring(0, 10)}...`}
                        </div>
                        <p className="text-[11px] text-stone-500">
                          {formatNotificationDate({ othertime: elem.msgAt })}
                        </p>
                      </div>
                      {elem.unreadcount > 0 && (
                        <div
                          className={`py-0 px-1 ml-4 text-sm w-fit h-fit rounded-[50%] top-2 right-10 ${
                            user?.role == 1
                              ? "bg-yellow-400"
                              : "bg-red-400 text-white"
                          }`}
                        >
                          {elem.unreadcount > 0 ? elem.unreadcount : ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex-col w-full p-4 pt-5 bg-white shadow-2xl flex-2 rounded-3xl">
          {activeuser._id && activeuser._id.trim() != "" ? (
            <MessagesSection
              setActiveUser={setActiveUser}
              activeuser={activeuser}
              inputtext={inputtext}
              setInputText={setInputText}
              setModalShow3={setModalShow3}
              setModalShow2={setModalShow2}
              fetchChatMsgs={fetchChatMsgs}
              msgs={msgs}
              setMsgs={setMsgs}
              change={change}
              setChange={setChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {showModal2 && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex flex-col items-start px-5 py-6 text-sm bg-white rounded-3xl max-w-[350px]">
                  <div className="self-stretch text-base font-medium leading-6 text-black">
                    Are you sure you want to report {activeuser?.name || "User"}
                    ?
                  </div>
                  {reports?.length > 0 &&
                    reports.map((elem, ind) => {
                      return (
                        <div
                          className="flex gap-4 mt-2 ml-5 text-black"
                          key={`Reports_${ind}`}
                        >
                          <input
                            className="shrink-0 h-7 rounded-md border border-solid border-stone-300 w-[22px]"
                            type="radio"
                            checked={selReport === elem}
                            onChange={(e) =>
                              checkBoxHandler({
                                checked: e.target.checked,
                                value: elem,
                              })
                            }
                          />
                          <div className="flex-auto mt-1">{elem}</div>
                        </div>
                      );
                    })}

                  <div className="flex gap-2.5 self-center mt-8 text-base text-center capitalize w-full">
                    <input
                      type="checkbox"
                      name="alsoBlock"
                      value={alsoBlock}
                      onChange={(e) => setalsoBlock(e.target.value)}
                      className="w-[20px] h-auto"
                    />{" "}
                    <div className="flex flex-col text-start">
                      <p className="text-sm">
                        Do you want to Block {activeuser?.name || "User"} ?
                      </p>
                      <p className="text-[10px]">
                        This person won't be able to message you
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2.5 self-center mt-8 text-base text-center capitalize">
                    <button
                      onClick={() => setModalShow2(false)}
                      className="justify-center px-7 py-2.5 text-black whitespace-nowrap bg-white border border-amber-300 border-solid rounded-[110px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={ReportUser}
                      className="justify-center px-3 py-2.5 text-white bg-red-500 rounded-[110px]"
                    >
                      Submit Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-5"></div>
        </>
      )}

      {showModal3 && (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-3xl mx-auto my-6">
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-2xl focus:outline-none">
                <div className="flex flex-col px-10 py-6 text-center bg-white rounded-3xl max-w-[323px]">
                  <div className="self-center text-lg font-medium leading-7 text-black">
                    Are you sure you want to block {activeuser?.name || "User"}?
                  </div>
                  <div className="flex gap-2.5 mt-6 text-base self-center capitalize whitespace-nowrap">
                    <button
                      onClick={() => setModalShow3(false)}
                      className="justify-center px-8 py-2.5 text-black bg-white border border-amber-300 border-solid rounded-[110px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => blockUsr(true)}
                      className="justify-center px-10 py-2.5 text-white bg-red-500 rounded-[110px]"
                    >
                      Block
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-5"></div>
        </>
      )}
    </>
  );
}

export default Chatroom;
