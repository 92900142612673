import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  unauthorized: false,
  alreadySignedIn: false,
  setServerOffline: false,
  setMessageOne: true,
  setMessageTwo: true,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setUnAuthorized(state, action) {
      state.unauthorized = action.payload;
    },
    setAlreadySigned(state, action) {
      state.alreadySignedIn = action.payload;
    },
    setServerOffline(state, action) {
      state.setServerOffline = action.payload;
    },
    setMessageOne(state, action) {
      state.setMessageOne = action.payload;
    },
    setMessageTwo(state, action) {
      state.setMessageTwo = action.payload;
    },
  },
});

export const {
  setUnAuthorized,
  setAlreadySigned,
  setServerOffline,
  setMessageOne,
  setMessageTwo,
} = globalSlice.actions;

export default globalSlice.reducer;
