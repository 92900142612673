import React, { useEffect, useState } from "react";
import style from "../Earning.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import WrapperDashEst from "../../authWrapper/WrapperDashEst";
import DropdownEst from "../../dashboard/Establishment/DropdownEst";
import NotificationEst from "../../dashboard/Establishment/NotificationEst";
import EstabDashHeader from "../../dashboard/Establishment/EstabDashHeader";
import { useSelector } from "react-redux";
import {
  DeleteCard,
  fetchAllCards,
  GetPaymentDetails,
  makePayment,
} from "../../../axios/axios";
import { toast } from "react-toastify";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { Button } from "antd";
import { HiBadgeCheck } from "react-icons/hi";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import configuration from "../../../config/configuration";

const stripePromise = loadStripe(configuration.stripePublicKey);

function PaymentOp() {
  const stripe = useStripe();
  const user = useSelector((state) => state.user?.user);
  const { bid, jid } = useParams();
  const navigate = useNavigate();
  const [isallow, setIsAllow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeCard, setActiveCard] = useState("");
  const [availcard, setAvailCard] = useState([]);
  const [change, setChange] = useState(false);
  const [paymentdetails, setPaymentDetails] = useState({
    Job_Pay: "",
    Stripe_Percentage: "",
    Total_Pay: "",
  });

  const getAllCards = () => {
    fetchAllCards({ token: user?.token })
      .then((res) => {
        setAvailCard(res.data);
        setIsAllow(true);
      })
      .catch((err) => console.log(err));
  };

  const getPayDetails = () => {
    GetPaymentDetails({ token: user?.token, B_Id: bid, Job_Id: jid })
      .then((res) => {
        const { Job_Pay, Stripe_Percentage, Total_Pay } = res.data;
        setPaymentDetails({ Job_Pay, Stripe_Percentage, Total_Pay });
      })
      .catch((err) => {
        console.log("ERROR HERE: ", err);
        toast.error(err.message);
        navigate(-1);
      });
  };

  const deleteCard = (cardId) => {
    DeleteCard({ token: user?.token, cardId })
      .then((res) => {
        setChange(!change);
        toast.success(res?.message);
      })
      .catch((err) => console.log(err));
  };

  const handlePaymentstatus = ({ status, url }) => {
    if (status) {
      toast.success("Payment Sucess");
      setLoading(false);
    } else if (!status) {
      console.log("Action Req: ", url);
      window.location.href = url;
    }
  };

  useEffect(() => {
    getAllCards();
    getPayDetails();
  }, [change]);

  const InitilizePayment = async (cardId) => {
    if (!activeCard) {
      toast.error("Select Card To Make Payment!");
      return;
    }
    setLoading(true);

    if (isallow) {
      setIsAllow(false);
      makePayment({ token: user?.token, B_Id: bid, Job_Id: jid, cardId })
        .then(async (res) => {
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            res.data.clientSecret,
            {
              payment_method: cardId,
            }
          );
          if (paymentIntent && paymentIntent.status === "succeeded") {
            toast.success("Payment Successful");
            setLoading(false);
            navigate("/dashboard/earnings");
          }
          if (error && error?.payment_intent.status === "succeeded") {
            toast.success("Payment Successful");
            setLoading(false);
            navigate("/dashboard/earnings");
          } else if (error) {
            console.log(error);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    }
  };

  return (
    <WrapperDashEst>
      <EstabDashHeader
        leftContent={
          <>
            <div className="pt-3 my-auto uppercase grow">
              <span className=" text-neutral-700">Payment Options</span>{" "}
            </div>
          </>
        }
      />
      <div className="relative flex h-[80%] max-w-[900px] mx-auto mt-5 items-start pt-5 pb-6 pl-6 w-full bg-white rounded-3xl shadow-2xl max-md:pr-5 max-md:mt-6 max-md:max-w-full">
        <div className="h-full max-md:w-full">
          <div className="flex justify-center items-center w-[350px]">
            <div
              className="flex flex-col justify-center px-6 cursor-pointer w-[260px] py-2.5 mx-auto  text-sm text-white bg-red-500 rounded-xl max-md:px-5 max-md:mt-10"
              onClick={() => navigate("./add-card")}
            >
              <div className="flex gap-2.5 py-1">
                <img
                  loading="lazy"
                  src="/images/completeProfile/white-plus.png"
                  className="w-5 shrink-0 aspect-square"
                />
                <div className="flex-auto my-auto">Add New Card</div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 overflow-hidden mt-2 h-[90%] justify-center items-center">
            <div className="flex flex-col h-full gap-5 overflow-y-scroll no-scrollbar">
              {availcard &&
                availcard.map((elem, ind) => (
                  <div
                    className="flex flex-col w-[260px] max-md:ml-0 max-md:w-full cursor-pointer"
                    key={`cards_${elem.id}_${ind}`}
                  >
                    <div
                      className="flex flex-col w-full pt-3 mx-auto bg-red-500 grow rounded-2xl max-md:mt-3"
                      onClick={() => setActiveCard(elem?.id)}
                    >
                      <div
                        to={"/CodeVerifyOp"}
                        className="flex flex-col px-2 tracking-wider text-white"
                      >
                        <div className="flex justify-between gap-5 text-xs font-medium">
                          <div className="items-center p-2 bg-red-600 w-fit h-fit rounded-xl ">
                            {elem?.brand}
                          </div>
                          {activeCard == elem?.id ? (
                            <HiBadgeCheck className="h-[80%] w-auto " />
                          ) : (
                            <MdOutlineDeleteSweep
                              className="h-[80%] w-auto "
                              onClick={() => deleteCard(elem.id)}
                            />
                          )}
                        </div>
                        <div className="mt-3 text-base">
                          **** **** **** {elem?.last4}
                        </div>
                      </div>
                      <div className="flex h-full gap-5 p-3 mt-8 bg-red-600 whitespace-nowrap rounded-b-2xl">
                        <div className="flex flex-col flex-1 tracking-wider">
                          <div className="text-xs text-slate-50">Name</div>
                          <div className="text-sm text-white ">
                            {elem?.name}
                          </div>
                        </div>
                        <div className="my-auto text-sm tracking-wider text-right text-white">
                          {elem?.exp_month < 10
                            ? `0${elem.exp_month}`
                            : elem.exp_month}
                          /{elem?.exp_year % 100}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {bid && jid ? (
          <div className="w-full h-[100%] p-4">
            <div className="w-full h-[100%] grid grid-rows-2 rounded-xl bg-white shadow-2xl">
              <div className="flex flex-col p-4 text-lg h-fit">
                <div className="flex justify-between mb-2">
                  <p className="w-1/2 text-right">Job Payment:</p>
                  <p className="w-1/2 text-center">
                    {parseFloat(paymentdetails.Job_Pay).toFixed(2)}
                  </p>
                </div>
                <div className="flex justify-between mb-2">
                  <p className="w-1/2 text-right">Platform Service Chagre:</p>
                  <p className="w-1/2 text-center">
                    {paymentdetails.Stripe_Percentage}
                  </p>
                </div>
                <div className="flex justify-between mb-2">
                  <p className="w-1/2 text-right">Total Payment Amount:</p>
                  <p className="w-1/2 text-center">
                    {parseFloat(paymentdetails.Total_Pay).toFixed(2)}
                  </p>
                </div>
              </div>
              <Button
                className="text-white bg-red-500 hover:bg-red-400"
                onClick={() => InitilizePayment(activeCard)}
                loading={loading}
              >
                Pay Now
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </WrapperDashEst>
  );
}

function WrappedPaymentOp() {
  return (
    <Elements stripe={stripePromise}>
      <PaymentOp />
    </Elements>
  );
}

export default WrappedPaymentOp;
