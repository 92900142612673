import React from 'react'

function SEOLandingSection4() {
    const listData = [
        {
            icon: '/images/01_SEO_Section_4.svg',
            title: 'Seasonal Staffing Solutions',
            desc: 'During peak seasons, such as summer or the holidays, your staffing needs may fluctuate. Barooom allows you to adjust your staff levels to meet demand, ensuring that you always have the right number of hands on deck to provide excellent service.'
        },
        {
            icon: '/images/02_SEO_Section_4.svg',
            title: 'Event-Specific Staffing',
            desc: 'If you’re hosting a special event, whether it’s a corporate gathering, festival, or private party, Barooom can help you find bartenders who have experience in handling large crowds and can create a fun, engaging atmosphere.'
        },
        {
            icon: '/images/03_SEO_Section_4.svg',
            title: 'Efficient Hiring Process',
            desc: 'We understand that time is of the essence in the hospitality industry. Our platform allows you to instantly connect with bartenders and staff that meet your specific needs, saving you valuable time.'
        },
        {
            icon: '/images/04_SEO_Section_4.svg',
            title: 'Verified Professionals',
            desc: 'All staff on our platform undergo a thorough vetting process. We check references, skills, and experience to ensure that you are hiring only the best talent available. This peace of mind allows you to focus on your business while we take care of staffing.'
        },
        {
            icon: '/images/05_SEO_Section_4.svg',
            title: 'Time-Saving',
            desc: 'Gone are the days of sifting through endless resumes and conducting multiple interviews. With Barooom, you can find quality candidates quickly and easily, allowing you to get back to what you do best—running your establishment.'
        },

    ]
    return (
        <section className='min-h-[100vh] relative flex items-center flex-col w-[100vw] pb-24'>
            <h1 className='text-4xl font-normal font-Righteous'>Top-Quality Talent, <span className='text-4xl font-Righteous text-error'> Just a Click Away</span></h1>
            <p className='pt-3 font-poppins text-gray-1 text-wrap max-w-[70%] text-center'>At Barooom, we pride ourselves on providing access to a diverse range of hospitality professionals. Here are the key roles you can find through our platform:</p>
            <button className='border-4 border-[#FF33336B] rounded-[25px] h-fit py-3 px-2 bg-[#FF3333] text-white font-poppins font-bold text-center mt-4'>
                Sign Up for Early Access
            </button>
            <div className='w-full grid-cols-2 mt-8 '>
                <div className='flex w-full gap-2'>
                    <img src="/images/SEO_Landing_01.svg" alt="" className='w-auto h-80' />
                    <div className='bg-[#2D2D2D] rounded-l-xl rounded-br-xl flex-col justify-center items-center flex w-[40%] px-2'>
                        <h2 className='text-3xl font-normal font-Righteous'>Bartender</h2>
                        <p className='px-4 pt-3 text-center text-white text-wrap font-poppins'>Our bartenders are skilled in crafting signature cocktails, managing bar duties, and delivering exceptional customer service. They bring creativity and expertise, ensuring that your bar stands out.
                        </p>
                    </div>
                    <div className='bg-no-repeat bg-contain bg-mixolog w-[40%] relative px-2'>
                        <div class="absolute inset-0 bg-[#F7B81F] bg-opacity-95 rounded-bl-xl"></div>
                        <div class="relative z-10 text-white justify-center items-center h-full flex flex-col">
                            <h2 className='text-3xl font-normal font-Righteous'>Mixologists</h2>
                            <p className='px-4 pt-3 text-center text-white text-wrap font-poppins'>For those looking to offer something unique, our mixologists specialize in creating innovative drinks that can elevate your menu. They are passionate about mixology and can design custom drink menus tailored to your clientele.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='flex w-full gap-2 mt-2'>
                    <div className='bg-[#F7B81F] rounded-tr-xl flex-col justify-center items-center flex w-[40%] h-80'>
                        <h2 className='text-3xl font-normal font-Righteous'>Servers and Waiters</h2>
                        <p className='px-4 pt-3 text-center text-white text-wrap font-poppins'>In addition to bartenders, we provide polished servers and waitstaff who are dedicated to providing top-tier service. These professionals are trained to handle food and beverage service with grace and efficiency, enhancing the overall dining experience for your guests.
                        </p>
                    </div>
                    <div className='bg-no-repeat bg-cover bg-seo-landing-back-card w-[40%] relative rounded-tl-xl rounded-br-xl'>
                        <div class="absolute inset-0 bg-[#2D2D2D] bg-opacity-85 rounded-tl-xl rounded-br-xl"></div>
                        <div class="relative z-10 text-white justify-center items-center h-full flex flex-col">
                            <h2 className='text-3xl font-normal font-Righteous'>Front of the House Support</h2>
                            <p className='px-4 pt-3 text-center text-white text-wrap font-poppins'>From hosts to cashiers, our front-of-house staff is ready to ensure that your operations run smoothly. These professionals are essential in creating a welcoming environment and managing guest interactions.
                            </p>
                        </div>
                    </div>
                    <div className='bg-[#F7B81F] rounded-l-xl rounded-br-xl flex-col justify-center items-center flex w-[40%] h-80 rounded-b-xl rounded-tr-xl'>
                        <h2 className='text-3xl font-normal font-Righteous'>Barbacks</h2>
                        <p className='px-4 pt-3 text-center text-white text-wrap font-poppins'>Barbacks play a crucial role in supporting bartenders by keeping the bar stocked and organized. They help ensure that everything runs seamlessly behind the scenes, allowing bartenders to focus on serving customers.
                        </p>
                    </div>
                    <div className='bg-seo-landing-back-card-01 rounded-l-xl w-[50%] h-80 bg-no-repeat bg-cover'></div>
                </div>
            </div >

            <div className='flex flex-col items-center justify-center w-full mt-32 '>
                <h1 className='text-4xl font-normal font-Righteous'><span className='text-4xl font-Righteous text-error'>Flexible Staffing</span>for All Your Needs</h1>
                <p className='pt-3 font-poppins text-gray-1 text-wrap max-w-[50%] text-center'>Whether you’re hosting a large event, need extra help for a busy weekend, or are looking for long-term staffing solutions, Barooom offers flexible options to fit your specific needs. With a comprehensive database of skilled professionals across Charlotte, you can easily find the perfect match for your establishment.</p>
            </div>
            <div className='relative flex w-full mt-4'>
                <div className='flex flex-col max-w-[40%] gap-5 ml-[10%] px-2'>
                    {listData.map((elem, ind) => (<div className='flex' key={ind}>
                        <img src={elem.icon} alt="" className='left-0 w-32 h-32 translate-x-[40%]' />
                        <label className='py-2 pl-12 pr-4 rounded-l-full shadow-lg '>
                            <h1 className='text-sm font-normal leading-8 font-Righteous'>
                                {elem.title}
                            </h1>
                            <p className='text-sm text-left font-poppins text-gray-1 text-wrap'>
                                {elem.desc}
                            </p>
                        </label>
                    </div>))}
                </div>

            </div>
            <img src="/images/SEO_Section_04_Background.svg" alt="" className='absolute right-0 w-[35%] h-auto bottom-0 translate-y-[10%]' />
        </section >
    )
}

export default SEOLandingSection4