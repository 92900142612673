import React, { useEffect, useState } from "react";
import style from "./ContentWrapper.module.css";
import { Link } from "react-router-dom";
import { getContent } from "../../axios/axios";
import { useSelector } from "react-redux";
// import style from "../Footer.module.css";

const ContentWrapper = ({ title, content, type }) => {
  const user = useSelector((state) => state?.user?.user);
  let [data, setData] = useState("");

  const fetchContent = () => {
    getContent({ type })
      .then((result) => {
        setData(result.data.data[type]);
      })
      .catch((e) => {});
  };

  fetchContent();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div
        style={{
          background: `url(/AboutBarrom/backgroundImg.png)`,
          backgroundSize: "cover",
        }}>
        {/* <header className={style.header}>
          {user ? (
            <></>
          ) : (
            <>
              <Link to={"/login"} className={style.changeColor}>
                Login
              </Link>
              <Link to={"/signup"} className={style.changeColor}>
                Signup
              </Link>
            </>
          )}
        </header> */}
        <Link to={"/"}>
          <img
            loading="lazy"
            className={style.barrom}
            src={"/LandingPageImages/Barrom.svg"}
            alt=""
          />
        </Link>
        <div className={style.lowerPart}>
          <div className={style.backgroundImg}>
            <h3>
              {title[0]} <span>{title[1]}</span>
            </h3>
          </div>
        </div>
      </div>
      <div className={style.middlePart}>
        <div
          className={style.contentSection}
          dangerouslySetInnerHTML={{ __html: data }}></div>
      </div>
    </>
  );
};

export default ContentWrapper;
