import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ensureEmail, ensureString } from "../utils/inputFormatter";
import { Button, Modal } from "antd";
import { deleteAccountInt, signInApi, verifyApi } from "../axios/axios";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { motion, AnimatePresence } from "framer-motion";
import Footer from "../components/landing/Footer";
import NewFooter from "../components/landing/NewFooter";

function DeleteAcc() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showOtp, setshowOtp] = useState(false);
  const [refId, setRefId] = useState("");
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    if (e.target.name === "email" || e.target.name === "password") {
      e.target.value = ensureEmail({ value: e.target.value });
    }

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const delteAccount = () => {
    if (!user.email || user.email == "") {
      toast.error("Enter Field Empty!");
      return;
    } else {
      setShowModal(true);
    }
  };

  //   const togglePasswordVisibility = () => {
  //     setPasswordVisible(!passwordVisible);
  //   };

  const loginFunc = async () => {
    try {
      setLoading(true);
      const data = await deleteAccountInt({ email: user?.email });
      console.log(data.data?.data?.refId);
      setRefId(data.data?.data?.refId);
      setshowOtp(true);
      showModal(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      setShowModal(false);
      setLoading(false);
    }
  };

  const DelAcc = async () => {
    try {
      setLoading(true);
      const data = await verifyApi({ otp, refId, type: 3 });
      toast.success(data.data.message);
      navigate("/");
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <div
      className="no-scrollbar"
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <header
        style={{
          paddingBottom: '20px',
          paddingLeft: '30px',
          paddingRight: '30px',
          color: 'white',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '20px',
          backgroundColor: '#78716c',
          height: '10%',
          borderBottom: '4px solid #FFCC42',
        }}
      >
        <img src="./images/barooom.png" alt="logo" />
      </header>

      <div
        style={{
          paddingTop: '5rem',
          paddingBottom: '5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AnimatePresence>
          {!showOtp ? (
            <motion.div
              key="showotp"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                backgroundColor: 'white',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '15px',
                padding: '16px',
                height: '80%',
              }}
              className="w-[25%] max-sm:w-full max-lg:w-[80%]"
            >
              <img
                style={{
                  height: '80px',
                  position: 'absolute',
                  top: '0',
                  transform: 'translateY(-50%)',
                }}
                src="./images/barooom.png"
                alt="logo"
              />
              <h1 className="mt-2 leading-6" style={{ fontSize: '1.25rem', fontFamily: 'Righteous', paddingTop: '1rem', textAlign: 'center' }}>
                You are about to delete your account
              </h1>

              <div
                style={{
                  display: 'flex',
                  gap: '16px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  padding: '8px 12px',
                  margin: '14px 28px',
                  fontSize: '0.875rem',
                  backgroundColor: 'white',
                  border: '1.24px solid #e5e7eb',
                  borderRadius: '124.047px',
                }}
              >
                <img
                  loading="lazy"
                  src="/images/person.png"
                  style={{ width: '25px', aspectRatio: '1.03' }}
                  alt="person"
                />
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                  <div style={{ color: 'black' }}>Enter Email ID or Phone Number</div>
                  <div style={{ marginTop: '4px', color: '#71717a' }}>
                    <input
                      style={{
                        width: '100%',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                      }}
                      type="text"
                      name="email"
                      value={user.email}
                      onChange={handleInputChange}
                      placeholder="Your email address or phone number"
                    />
                  </div>
                </div>
              </div>

              <Button
                loading={loading}
                type="text"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '12px',
                  width: '85%',
                  marginTop: '16px',
                  fontSize: '0.875rem',
                  textAlign: 'center',
                  color: 'black',
                  backgroundColor: '#fbbf24',
                  borderRadius: '124.047px',
                  margin: '12px',
                }}
                onClick={delteAccount}
              >
                Delete Account
              </Button>
            </motion.div>
          ) : (
            <motion.div
              key="bartenderavail"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                width: '25%',
                backgroundColor: 'white',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '15px',
                padding: '16px',
                height: '80%',
              }}
            >
              <img
                style={{
                  height: '80px',
                  position: 'absolute',
                  top: '0',
                  transform: 'translateY(-50%)',
                }}
                src="./images/barooom.png"
                alt="logo"
              />
              <h1 style={{ fontSize: '1.25rem', fontFamily: 'Righteous' }}>
                Enter OTP to verify and delete your account
              </h1>
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span></span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      height: '55px',
                      width: '55px',
                      textAlign: 'center',
                      borderRadius: '50%',
                      border: '1px solid lightgray',
                      margin: '15px 5px',
                      outline: 'none',
                      fontSize: '14px',
                    }}
                  />
                )}
              />
              <Button
                loading={loading}
                type="text"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '12px',
                  width: '85%',
                  marginTop: '16px',
                  fontSize: '0.875rem',
                  textAlign: 'center',
                  color: 'black',
                  backgroundColor: '#fbbf24',
                  borderRadius: '124.047px',
                  margin: '12px',
                }}
                onClick={DelAcc}
              >
                Confirm Delete Account
              </Button>
            </motion.div>
          )}
        </AnimatePresence>

        <Modal
          open={showModal}
          centered
          footer={null}
          onOk={() => console.log("THIS")}
          onCancel={() => setShowModal(false)}
        >
          <div style={{ height: '128px', color: 'black' }}>
            <h1 style={{ fontSize: '1.25rem', fontFamily: 'Righteous', }}>
              Are you sure you want to delete your account?
            </h1>
            <div style={{ display: 'flex', width: '100%', paddingTop: '20px', justifyContent: 'space-evenly' }}>
              <button
                style={{
                  fontSize: '1rem',
                  padding: '16px',
                  borderRadius: '10px',
                  border: '2px solid #fbbf24',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
                onClick={() => {
                  loginFunc();
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = '#78716c')}
                onMouseOut={(e) => (e.target.style.backgroundColor = 'transparent')}
              >
                Yes
              </button>
              <button
                style={{
                  fontSize: '1rem',
                  padding: '16px',
                  borderRadius: '10px',
                  border: '2px solid #fbbf24',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
                onClick={() => {
                  setShowModal(false);
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = '#78716c')}
                onMouseOut={(e) => (e.target.style.backgroundColor = 'transparent')}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <NewFooter/>
    </div>

  );
}

export default DeleteAcc;
