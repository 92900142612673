import { Button } from 'antd'
import React, { useState } from 'react'
import { updateBartPayment } from '../../axios/axios';
import { useSelector } from 'react-redux';
import Stripe from "stripe"
import configuration from '../../config/configuration';
import { toast } from 'react-toastify';
import { ensureNumber, ensureString } from '../../utils/inputFormatter';
const stripe = Stripe(configuration.stripeKey);

function AddBankDetailsModal({ showModal, setShowModal, hourlyRates }) {
    const user = useSelector((state) => state.user.user)
    const [apiLoading, setapiLoader] = useState(false)
    const [bankData, setBankData] = useState({
        bankName: '',
        holderName: '',
        bankCode: '',
        accountType: 'Individual',
        routingNumber: '',
        accountNumber: '',
    })

    const submitHandler = async () => {
        setapiLoader(true);

        if (
            !bankData.holderName ||
            !bankData.accountType ||
            !bankData.routingNumber ||
            !bankData.bankName ||
            !bankData.bankCode ||
            !bankData.accountNumber
        ) {
            toast.error("Bank Details Empty!");
            setapiLoader(false);
            return;
        }
        try {
            const tok = await stripe.tokens.create({
                bank_account: {
                    country: "US",
                    currency: "usd",
                    account_holder_name: bankData.holderName,
                    account_holder_type: bankData.accountType,
                    routing_number: bankData.routingNumber,
                    account_number: bankData.accountNumber,
                },
            });

            updateBartPayment({
                data: { hourlyRates: hourlyRates, bankToken: tok.id },
                token: user.token,
            })
                .then((result) => {
                    setapiLoader(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setapiLoader(false);

                    console.log("err", err);
                });

        } catch (error) {
            toast.error(error.message);
        }
    };
    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">

                <div className='p-4 bg-white rounded-xl w-[30%] '>
                    <div
                        className={`text-xl leading-9 text-left text-black capitalize max-md:mt-10 max-md:max-w-full}`}>
                        Set up the Payment Account
                    </div>
                    <div className="mt-2.5 text-neutral-600 text-left max-md:max-w-full">
                        Fill Your Bank Details.
                    </div>
                    <div className="px-5 pt-3.5 pb-4 mt-6 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                        <div>
                            Bank Name <span className="text-error">*</span>
                        </div>
                        <input
                            className="w-full pt-2 border-none outline-none"
                            type="text"
                            name={"bankName"}
                            value={bankData.bankName}
                            placeholder='Enter Your Bank Name'
                            onChange={(e) => setBankData({ ...bankData, bankName: e.target.value })}
                        />
                    </div>
                    <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                        <div>
                            Account Holder’s Name <span className="text-error">*</span>
                        </div>
                        <input
                            className="w-full pt-2 border-none outline-none"
                            type="text"
                            name={"holderName"}
                            value={bankData.holderName}
                            placeholder={`Enter Account Holder's Name`}
                            onChange={(e) => setBankData({ ...bankData, holderName: e.target.value })}
                        />
                    </div>
                    <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                        <div>
                            Bank Code <span className="text-error">*</span>
                        </div>
                        <input
                            className="w-full pt-2 border-none outline-none"
                            type="text"
                            name={"bankCode"}
                            value={bankData.bankCode}
                            placeholder={`Enter Bank Code`}
                            onChange={(e) => setBankData({ ...bankData, bankCode: e.target.value })}
                        />
                    </div>
                    <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                        <div>
                            Account Number <span className="text-error">*</span>
                        </div>
                        <input
                            className="w-full pt-2 border-none outline-none"
                            type="text"
                            name={"accountNumber"}
                            value={bankData.accountNumber}
                            placeholder={`Enter Account Number`}
                            onChange={(e) => {
                                let value = ensureNumber({ value: e.target.value, maxLength: 12 })
                                setBankData({ ...bankData, accountNumber: value })
                            }}
                        />
                    </div>
                    <div className="px-5 pt-3.5 pb-4 mt-3.5 mb-0 text-black text-start bg-white border-gray-200 border-solid border-[0.922px] rounded-[92.207px] max-md:max-w-full">
                        <div>
                            Routing Number <span className="text-error">*</span>
                        </div>
                        <input
                            className="w-full pt-2 border-none outline-none"
                            type="text"
                            name={"routingNumber"}
                            value={bankData.routingNumber}
                            placeholder={`Enter Routing Number`}
                            onChange={(e) => {
                                let value = ensureNumber({ value: e.target.value, maxLength: 9 })
                                setBankData({ ...bankData, routingNumber: value })
                            }}
                        />
                    </div>

                    <div className='flex w-full gap-2'>
                        <Button
                            // loading={apiLoading}
                            type="text"
                            onClick={submitHandler}
                            style={{ height: "auto" }}
                            className="justify-center w-1/2 px-5 py-5 mt-3.5 text-sm text-center text-white whitespace-nowrap bg-amber-300 rounded-[92.207px] max-md:max-w-full">
                            Update
                        </Button>
                        <Button
                            type="text"
                            style={{ height: "auto" }}
                            onClick={() => setShowModal(!showModal)}
                            className="justify-center w-1/2 px-5 py-5 mt-3.5 text-sm text-center text-amber-300 whitespace-nowrap border-amber-300 rounded-[92.207px] max-md:max-w-full">
                            Cancel
                        </Button>

                    </div>
                </div>

            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>

        </>
    )
}

export default AddBankDetailsModal