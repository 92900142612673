import * as React from "react";
import styles from "../styles/login.module.css";
import { Link, useLocation } from "react-router-dom";
import {
  appleLoginApi,
  googleLoginapi,
  signUpApi,
  UpdateNoitfyToken,
} from "../axios/axios";
import { useState, useEffect } from "react";
import { getToken } from "firebase/messaging";
// import { messaging } from "../firebase";
import { useNavigate } from "react-router-dom";
import CameraSvg from "../svg/camera";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Alert } from "@mui/material";
import config from "../config/configuration";
import AppleLogin from "react-apple-login";

import NotAuthenticatedLayout from "../layouts/NotAuthenticatedLayout";
import {
  ensureEmail,
  ensureNumber,
  ensureString,
} from "../utils/inputFormatter";
import { Button } from "antd";
import { Bounce, toast } from "react-toastify";
import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { login } from "../redux/userSlice";

export default function SignUp(props) {
  const navigate = useNavigate();
  const loc = useLocation();
  const query = new URLSearchParams(loc.search);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [src, setSrc] = useState("");
  const [termsAgree, setTermsAgree] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNo: "",
    password: "",
    referralCode: query.get("ref"),
    notifytoken: "",
    role: activeTab,
    profile: null,
  });

  const handleInputChange = (e) => {
    if (
      e.target.name === "email" ||
      e.target.name === "password" ||
      e.target.name === "referralCode"
    ) {
      e.target.value = ensureEmail({ value: e.target.value });
    }
    if (e.target.name === "name") {
      e.target.value = ensureString({ value: e.target.value });
    }

    if (e.target.name === "phoneNo") {
      e.target.value = ensureNumber({ value: e.target.value, maxLength: 10 });
    }

    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignUp = async () => {
    try {
      // check of email and null values
      if (!user.email || !user.password) {
        toast.error("Email/Password cannot be Empty!");
        return;
      }
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // const regex = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
      if (user.email && !regex.test(user.email)) {
        toast.error("Enter Valid Email ID!");
        return;
      }
      setLoading(true);
      const response = await signUpApi(user);
      navigate("/verify", { state: response.data.data.id });
    } catch (error) {
      setLoading(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const googleSuccess = (res) => {
    googleLoginapi({ access_token: res.access_token, role: user.role })
      .then((response) => {
        let usr = {
          ...response.data.data.user,
          token: response.data.data.token,
        };

        dispatch(login(usr));
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };
  const [notifytoken, setNotifyToken] = useState("");

  const googleFailure = (err) => {
    toast.error("Google Login Failed", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  };

  const Glogin = useGoogleLogin({
    onSuccess: googleSuccess,
    onError: googleFailure,
  });

  const appleCallback = (response) => {
    if (response.error) {
      toast.error("Apple Login Failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      return;
    }

    appleLoginApi({ ...response, role: user.role, referal_code: "" })
      .then((result) => {
        let usr = { ...result.data.data.user, token: result.data.data.token };
        if (notifytoken && notifytoken != "") {
          UpdateNoitfyToken({
            token: response.data.data.token,
            notifytoken: notifytoken,
          });
        }

        dispatch(login(usr));

        // var x = {...result.data.data,token:result.data.token}
        // localStorage.setItem("bzuser",JSON.stringify(x));
        // setUser(x);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  // async function requrestPermission() {
  //   console.log("Function Called");
  //   const permission = await Notification.requestPermission();
  //   console.log("Permission Status:", permission);
  //   if (permission === "granted") {
  //     const Tok = await getToken(messaging, {
  //       vapidKey:
  //         "BMZr2DUs-guA3GTCZzLU8Z9CgmV6t_8ULZMRl6bN9LI4KLDytxnZCF-Kh-8H2iYAGs242uuCBsxMswTbE9ThUqs",
  //     });
  //     console.log(Tok);
  //     setUser({ ...user, notifytoken: Tok });
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }

  const imageChangeHandler = async (e) => {
    setUser((prev) => ({
      ...prev,
      profile: e.target.files[0],
    }));

    let data = await getSrcFromfile(e.target.files[0]);

    setSrc(data);
  };

  const getSrcFromfile = (file) => {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.readAsDataURL(file);

      fr.onload = function (e) {
        resolve(e.target.result);
      };
    });
  };

  // useEffect(() => {
  //   requrestPermission();
  // }, []);

  useEffect(() => {
    setUser((prev) => ({
      ...prev,
      profile: null,
      role: activeTab,
    }));
  }, [activeTab]);

  return (
    <NotAuthenticatedLayout>
      <div
        className={styles.container}
        style={{
          backgroundImage:
            activeTab === 1
              ? "url('/images/background-Image.png')"
              : `url("/images/BackgroundImage.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
        <div className={styles.dashboardcontainer}>
          <div className={styles.upperImg}>
            <img src="./images/barooom.png" />
          </div>

          <div className="flex flex-col items-center bg-white border-solid border-[5.241px] w-[100%] border-[color:var(--ffcc-42,#FFCC42)] rounded-[41.924px]">
            <div className={styles.header}>sign Up</div>
            <div className="flex flex-col items-center ">
              <div className="flex gap-2 justify-center px-1.5 py-1 mt-3 text-base shadow-[0_1px_5px_rgb(0,0,0,0.2)] leading-6 text-center whitespace-nowrap bg-white rounded-[132.798px]">
                <div
                  style={{ flex: 1, cursor: "pointer" }}
                  onClick={() => setActiveTab(1)}
                  className={
                    activeTab === 1
                      ? `justify-center px-9 py-3 font-medium text-white bg-red-500 rounded-[132.798px] max-md:px-5`
                      : `justify-center px-9 py-3 max-md:px-5 text-neutral-400`
                  }>
                  Bartender
                </div>

                <div
                  style={{ flex: 1, cursor: "pointer" }}
                  onClick={() => setActiveTab(2)}
                  className={
                    activeTab === 2
                      ? `justify-center px-9 py-3 font-medium text-white bg-red-500 rounded-[132.798px] max-md:px-5`
                      : `justify-center px-9 py-3 max-md:px-5 text-neutral-400`
                  }>
                  Establishment
                </div>
              </div>

              {activeTab === 2 && (
                <div
                  className={styles.profileImgCnt}
                  style={{
                    backgroundImage: user.profile !== null && `url(${src})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}>
                  {user.profile === null && (
                    <PersonIcon
                      sx={{ height: "80%", width: "80%", color: "gainsboro" }}
                    />
                  )}

                  <div className={styles.profileImgBtnCnt}>
                    <CameraSvg />
                    <input
                      type="file"
                      id="profile-img"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={imageChangeHandler}
                    />
                    <label htmlFor="profile-img"></label>
                  </div>
                </div>
              )}

              <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/person.png"
                  className="my-auto aspect-[1.03] w-[25px]"
                />
                <div className="flex flex-col flex-1 max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">
                    Your Full Name
                  </div>
                  <div className="mt-1 text-zinc-500 max-md:max-w-full ">
                    <input
                      className="w-full border-none outline-none "
                      type="text"
                      name="name"
                      value={user.name}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      placeholder="Enter your full name"
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/mail.png"
                  className="my-auto aspect-[1.03] w-[25px]"
                />
                <div className="flex flex-col flex-1 max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">Email ID</div>
                  <div className="mt-1 text-zinc-500 max-md:max-w-full ">
                    <input
                      className="w-full border-none outline-none"
                      type="text"
                      name="email"
                      value={user.email}
                      onChange={handleInputChange}
                      placeholder="Your email address or phone number"
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/contactLogo.png"
                  className="my-auto aspect-[1.03] w-[25px]"
                />
                <div className="flex flex-col flex-1 max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">
                    Phone Number
                  </div>
                  <div className="mt-1 text-zinc-500 max-md:max-w-full ">
                    <input
                      className="w-full border-none outline-none"
                      type="text"
                      name="phoneNo"
                      value={user.phoneNo}
                      onChange={handleInputChange}
                      placeholder="Enter your phone number"
                    />
                  </div>
                </div>
              </div>

              <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7 mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/lock.png"
                  className="self-stretch my-auto aspect-[1.03] w-[25px]"
                  alt="lock"
                />
                <div className="flex flex-col self-stretch flex-1 px-0 max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">
                    Create Password
                  </div>

                  <div className="mt-1 text-zinc-500 max-md:max-w-full">
                    <input
                      className="w-full border-none outline-none"
                      type={passwordVisible ? "text" : "password"} // Conditionally set input type based on password visibility
                      name="password"
                      value={user.password}
                      onChange={handleInputChange}
                      placeholder="Enter your password"
                    />
                  </div>
                </div>
                {passwordVisible ? (
                  <VisibilityOffIcon
                    sx={{ color: "gray", cursor: "pointer" }}
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: "gray", cursor: "pointer" }}
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
              {/* <div className="flex gap-5 justify-between items-center self-stretch px-3 py-2 ml-7 mr-7  mt-3.5 text-sm bg-white border-gray-200 border-solid border-[1.24px] rounded-[124.047px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
                <img
                  loading="lazy"
                  src="/images/multiUser.png"
                  className="my-auto aspect-[1.03] w-[25px]"
                />
                <div className="flex flex-col flex-1 max-md:max-w-full">
                  <div className="text-black max-md:max-w-full">
                    Referral Code
                  </div>
                  <div className="mt-1 text-zinc-500 max-md:max-w-full ">
                    <input
                      className="w-full border-none outline-none "
                      type="text"
                      name="referralCode"
                      onChange={handleInputChange}
                      value={user.referralCode}
                      placeholder="Enter your referral code"
                    />
                  </div>
                </div>
              </div> */}

              {error && (
                <Alert
                  severity="error"
                  className="justify-center p-3 w-[85%] mt-4 text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3">
                  {error}
                </Alert>
              )}

              <Button
                loading={loading}
                disabled={!termsAgree}
                type="text"
                style={{ height: "auto" }}
                className={`${styles.btnMain} justify-center p-3 mt-4 w-[85%] text-small text-center text-black bg-amber-300 rounded-[124.047px] max-md:px-5 max-md:max-w-full my-3`}
                onClick={handleSignUp}>
                Sign Up
              </Button>
              <div className="self-center mt-3 text-small text-center text-black-500 w-[323px]">
                <input
                  type="checkbox"
                  checked={termsAgree}
                  onChange={(e) => setTermsAgree(!termsAgree)}
                  id="chkbox"
                  style={{ height: "15px", width: "15px", margin: "0 5px 0 0" }}
                />
                <label htmlFor="chkbox">
                  By clicking this button, you agree with{" "}
                </label>
                <br />
                {/* our <span className="text-red-500">Terms and Condition</span> */}
                <Link className="text-red-500" to="/terms-conditions">
                  Terms and Condition
                </Link>
              </div>
              <div className="flex gap-2.5 items-center px-5 text-sm font-medium text-zinc-600 my-5">
                <img
                  loading="lazy"
                  src="/images/rectangle1.png"
                  className="self-stretch my-auto max-w-full aspect-[100] fill-[linear-gradient(270deg,rgba(217,217,217,0.75)_0%,rgba(217,217,217,0.00)_94.39%)] w-[126px]"
                />
                <div className="self-stretch flex-auto">Or continue with</div>
                <img
                  loading="lazy"
                  src="/images/rectangle2.png"
                  className="self-stretch my-auto max-w-full aspect-[100] fill-[linear-gradient(90deg,rgba(217,217,217,0.75)_0%,rgba(217,217,217,0.00)_105.61%)] w-[126px]"
                />
              </div>
              <div className="flex gap-2.5 ">
                <div
                  onClick={Glogin}
                  className="flex flex-1 justify-center items-center px-10 py-5 cursor-pointer bg-white shadow rounded-[120.9px]">
                  <img
                    loading="lazy"
                    src="/images/google.png"
                    className="w-5 aspect-[0.96]"
                  />
                </div>

                <AppleLogin
                  clientId={config.appleClientId}
                  redirectURI={config.appleRedirectUri}
                  callback={appleCallback}
                  usePopup={true}
                  scope="email name"
                  responseMode="query"
                  render={(
                    renderProps //Custom Apple Sign in Button
                  ) => (
                    <div
                      onClick={renderProps.onClick}
                      className="flex flex-1 justify-center items-center px-10 py-2 cursor-pointer bg-white shadow rounded-[120.9px]">
                      <img
                        loading="lazy"
                        src="/images/apple-black.png"
                        className="w-6 aspect-[0.83]"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="my-4 text-center text-red-500 text-small ">
                <span className="text-zinc-600">New to Barooom? </span>
                <Link to="/login">Sign In</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NotAuthenticatedLayout>
  );
}
